/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import locatingFns from "../../common/GoogleLocating";
import { withRouter } from "react-router-dom";
import { LocationsInput } from "./LocationsInput";
import ValidationErrorToast from "../../components/validationErrorToast";
import { isEmpty, includes } from "lodash";
import { cookieService, userService } from "../../_services";
import SuccessToast from "../../components/successToast";
import { Data } from "../../_services/collections";

declare global {
  interface Window {
    google: any;
  }
}

let tempSecondaryAddress = []; // Array is used to preserve all changed addresses before user save

const BusinessLocation = (props: any) => {
  const [address, setAddress] = useState<any>({});
  const searchRef = useRef<any>(null);

  const [totalAddress, setTotalAddress] = useState<any>([]);
  const [key, setKey] = useState(1);
  const [secondaryAddresses, setSecondaryAddresses] = useState([]);
  const [primaryAddress, setPrimaryAddress] = useState<any>();
  const [btnClick, setBtnClick] = useState(false);
  // const [isSelected,setIsSelected] = useState(false)
  const isSelectedRef = useRef(false);
  const isPrimarySelectedRef = useRef(false);
  const refArray = useRef([]);
  const isSelectedRefArray = useRef([]);
  console.log("primaryAddress", primaryAddress);
  console.log("secondaryAddresses", secondaryAddresses);
  console.log("totalAddress", totalAddress);
  console.log("isSelectedRef", isSelectedRef);
  console.log("refArray", refArray);
  console.log("isPrimarySelectedRef", isPrimarySelectedRef);
  console.log("isSelectedRefArray", isSelectedRefArray);
  // Trigger when user changes any input in secondary addresses
  const handleAddressChange = (address, idx) => {
    console.log("mjhgre4567trfdfghjjfdfg", idx + 1);
    tempSecondaryAddress[idx] = address.address; //Storing changed address at the corresponding index in SecondaryAddress array
    // const updatetempSA = secondaryAddresses.map((item, i) => {
    //     if (tempSecondaryAddress[i]) {
    //         return { ...item, id: i, address: tempSecondaryAddress[i] }// Overwrite the id and address which the latets values
    //     }
    //     return item;
    // })
    let updatetempSA = secondaryAddresses?.map((item, index) => {
      if (index == idx) {
        isSelectedRef.current = true;
        isSelectedRefArray.current[index] = true;
        return { id: index + 1, address: address?.address };
      } else {
        return item;
      }
      //             if(item==idx+1){
      // return {id: idx+1, address: address?.address }
      //             }else if(item?.id==idx+1){
      //                 return {...item, address: address?.address }
      //             }
      //             else{
      //                 return item
      //             }
    });
    //     console.log("updatetempSA",updatetempSA)
    setSecondaryAddresses(updatetempSA); //set secondary array with latest values.
  };

  const handleDelete = (id, idx, val) => {
    console.log("secondaryAddresses", secondaryAddresses);
    console.log("idx", idx);
    // console.log(id,idx,secondaryAddresses)
    let temp = secondaryAddresses.filter((item, index) => {
      return index !== idx;
    });
    setSecondaryAddresses(temp);
    // if(val==""){
    //     isSelectedRef.current=true
    // }else{

    // }
    refArray.current = refArray.current.filter((item, index) => {
      return index !== idx;
    });
    isSelectedRefArray.current = isSelectedRefArray.current.filter(
      (item, index) => {
        return index !== idx;
      }
    );

    // const total = [...totalAddress];
    // total.splice(idx, 1);
    // const secondary = [...secondaryAddresses];
    // secondary.splice(idx, 1);
    // setTotalAddress(total);
    // setSecondaryAddresses(secondary);

    // setSecondaryAddresses((prev) => {
    //     return prev.filter(item => item.id != id)
    // })
    // setTotalAddress((prev) => {
    //     return prev.filter(item => item != id)
    // })
  };

  const allAddresses = [primaryAddress, ...secondaryAddresses];

  // load map script after mounted
  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, []);

  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${locatingFns.mapApiJs}?key=${locatingFns.apiKey}&libraries=places&v=weekly`;
    return locatingFns.loadAsyncScript(src);
  };

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    const lat = place?.geometry?.location?.lat();
    const lng = place?.geometry?.location?.lng();
    setAddress(locatingFns.extractAddress(place));
    isPrimarySelectedRef.current = true;
    setPrimaryAddress((prev) => {
      return {
        ...prev,
        address: searchRef.current.value,
        latitude: lat,
        longitude: lng,
        radius: 0,
        id: 0,
        isPrimary: true,
      };
    });
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchRef.current) return;
    const autocomplete = new window.google.maps.places.Autocomplete(
      searchRef.current,
      locatingFns.restrictionObj
    );
    window.google.maps.event.addListener(
      autocomplete,
      "place_changed",
      function () {
        let location = locatingFns.getDataFromParseGoogleAddressComponents(
          autocomplete.getPlace()
        );
        setPrimaryAddress((prev) => {
          return { ...prev, ...location };
        });
      }
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  // A function that searches on google using given latitude and longitude
  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    document.body.classList.add("loading-indicator");
    const url = `${locatingFns.geocodeJson}?key=${locatingFns.apiKey}&latlng=${lat},${lng}`;
    searchRef.current.value = "Getting your location...";
    fetch(url)
      .then((response) => response.json())
      .then((location) => {
        const place = location.results[0];
        const _address = locatingFns.extractAddress(place);
        setAddress(_address);

        searchRef.current.value = _address.plain();
        setPrimaryAddress({
          id: 0,
          isPrimary: true,
          radius: 0,
          latitude: lat,
          longitude: lng,
          address: searchRef.current.value,
          ...locatingFns.getDataFromParseGoogleAddressComponents(
            location.results[0]
          ),
        });
        isPrimarySelectedRef.current = true;
        document.body.classList.remove("loading-indicator");
      });
  };

  function findMyLocation() {
    document.body.classList.add("loading-indicator");
    return new Promise((resolve, reject) => {
      navigator.permissions
        ? // Permission API is implemented
          navigator.permissions
            .query({
              name: "geolocation",
            })
            .then((permission) =>
              // is geolocation granted?
              includes(["granted", "prompt"], permission.state)
                ? navigator.geolocation.getCurrentPosition((position) =>
                    reverseGeocode(position.coords)
                  )
                : (document.body.classList.remove("loading-indicator"),
                  ValidationErrorToast(
                    STRING_CONSTANTS.common.access_denied,
                    STRING_CONSTANTS.common.permission_denied
                  ))
            )
        : // Permission API was not implemented
          reject(
            new Error("Permission API is not supported") &&
              ValidationErrorToast(
                STRING_CONSTANTS.common.access_denied,
                STRING_CONSTANTS.common.permission_denied
              ) &&
              document.body.classList.remove("loading-indicator")
          );
    });
  }

  const addSecondary = () => {
    if (primaryAddress) {
      setKey((prev) => prev + 1);
      if (secondaryAddresses.length <= 3) {
        // setIsSelected(false)
        isSelectedRef.current = false;
        isSelectedRefArray.current[secondaryAddresses.length] = false;
        setTotalAddress([...totalAddress, totalAddress.length + 1]);
        setSecondaryAddresses([
          ...secondaryAddresses,
          secondaryAddresses.length + 1,
        ]);
      }
    } else
      ValidationErrorToast(
        "Primary Location",
        "Please add primary location first"
      );
  };

  const checkValidation = () => {
    let isAllTrue = isSelectedRefArray.current.every((item) => {
      return item == true;
    });

    return isAllTrue;
  };

  // Handles Address form submission
  const submitHandler = (e) => {
    // console.log("primaryAddress", primaryAddress, (searchRef?.current?.value !== primaryAddress?.address), primaryAddress?.address.replace(/ /g, '').length == 0)
    // console.log("secondaryAddresses", secondaryAddresses)
    e.preventDefault();
    // If primary address has not been added
    if (
      !primaryAddress ||
      searchRef?.current?.value !== primaryAddress?.address ||
      primaryAddress?.address.replace(/ /g, "").length == 0||isPrimarySelectedRef.current==false
    ) {
      setBtnClick(true);
      ValidationErrorToast(
        "Primary Location",
        "Please add a valid primary location first"
      );
    } else if (UtilityFunctions.hasDataType(secondaryAddresses, "number")) {
      ValidationErrorToast(
        "Secondary Location",
        "Please add a valid secondary location"
      );
    }

    // else if (secondaryAddresses?.map?.((address) => { if (typeof address === "number") { return true } else return false })) {
    //     ValidationErrorToast('Secondary Location', 'Please add a valid secondary location')
    // }
    else {
      if (checkValidation()) {
        // delete allAddresses['id']
        allAddresses.map((i, index) => {
          // if (!i.isPrimary) {
          i["order"] = index;
          delete i?.id;
          return i;
          // }
        });
        userService
          .userProfile({ locations: allAddresses })
          .then((res) => {
            if (props?.updateAddress) {
              props.history.push(
                STRING_CONSTANTS.navigationString.dashboard.settings
              );
              SuccessToast("Update Address", "Successfully updated address");
            } else {
              props.nav.next();
            }
          })
          .catch((e) => ValidationErrorToast(e[0], e[1]));
      }else{
        ValidationErrorToast(
            "Secondary Location",
            "Please add a valid secondary location"
          );
      }
    }
    tempSecondaryAddress = [];
  };
  //   const submitHandler = (e) => {
  //     e.preventDefault();
  //     console.log("isSelectedRef", isSelectedRef?.current);
  //     console.log("refArray", refArray?.current);
  //     console.log("searchRef", searchRef?.current?.value);
  //     console.log("isPrimarySelectedRef", isPrimarySelectedRef);
  //     console.log("isSelectedRefArray", isSelectedRefArray);
  //   };

  useEffect(() => {
    const data = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user); //loading user data form cookies
    const locations: Array<any> = (data as Data)?.locations || []; //extracting location data from user data
    const secondaryAdd = []; //initialize empty array for secondary location
    let primaryAdd = null; ////initialize null object for primary location
    if (locations?.length > 0) {
      //checking if user already address fill or not if fill in that case we have getting array of locations
      locations?.map((item) => {
        if (item?.isPrimary) {
          primaryAdd = item; //updating primary address
        } else {
          secondaryAdd.push(item); //pushing data into secondry array
        }
        return item;
      });
    }
    if (primaryAdd) {
      searchRef.current.value = primaryAdd?.address || ""; //updating input ref if primary add present;
      setPrimaryAddress(primaryAdd); //updating primary address  state
      isPrimarySelectedRef.current = true;
    }
    if (secondaryAdd?.length > 0) {
      const totalAdd = Array.apply(null, Array(secondaryAdd?.length)).map(
        (_, idx) => idx + 1
      ); //generating total array as per secondary address selected by user
      secondaryAdd.map((item, idx) => (item.id = idx + 1));
      setTotalAddress(secondaryAdd); //updating total address state

      setSecondaryAddresses(secondaryAdd); //updating secondary address state
    }
    tempSecondaryAddress = [];
  }, []);

  return (
    <Fragment>
      <div className="top-divde-spa">
        <span>
          <i
            className="fa fa-angle-left text-white d-flex justify-content-between"
            aria-hidden="true"
            onClick={() => {
              props.updateAddress
                ? props.history.push(
                    STRING_CONSTANTS.navigationString.dashboard.settings,
                    { tab: "updateProfile" }
                  )
                : props?.nav?.back();
            }}
          ></i>
        </span>
        <div className="step-content signup-mar">
          <button type="button" disabled>
            {
              STRING_CONSTANTS.employerProfileCompletionPages.business_location
                .business_location
            }
          </button>
          <p>
            {
              STRING_CONSTANTS.employerProfileCompletionPages.business_location
                .enter_business_location
            }
          </p>
          <div className="step-text">
            {props.updateAddress
              ? ""
              : STRING_CONSTANTS.employerProfileCompletionPages
                  .business_location.step}
          </div>
        </div>
      </div>
      <form className="form-wrap" onSubmit={(e) => submitHandler(e)}>
        <div className="v-form-width">
          <div className="scroll-inputs">
            <div className="busins-location">
              <div className="form-floating mb-3">
                <input
                  onBlur={() => {
                    if (isPrimarySelectedRef?.current == false) {
                      searchRef.current.value = "";
                    }
                  }}
                  onChange={() => {
                    isPrimarySelectedRef.current = false;
                  }}
                  ref={searchRef}
                  type="text"
                  className={`form-control ${
                    btnClick && isEmpty(searchRef?.current?.value)
                      ? "error"
                      : ""
                  }`}
                  placeholder="Enter Primary Business Address"
                />
                <label htmlFor="floatingInput">
                  {STRING_CONSTANTS.formLabelString.label_primary_address}
                </label>
                <button
                  className="current-location-btn"
                  type="button"
                  onClick={findMyLocation}
                >
                  Use Current Location
                  {/* <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 9.5C8.38071 9.5 9.5 8.38071 9.5 7C9.5 5.61929 8.38071 4.5 7 4.5C5.61929 4.5 4.5 5.61929 4.5 7C4.5 8.38071 5.61929 9.5 7 9.5ZM7.5 0.5C7.5 0.223858 7.27614 0 7 0C6.72386 0 6.5 0.223858 6.5 0.5V2.02487C4.13785 2.2594 2.25932 4.13788 2.02471 6.5H0.5C0.223858 6.5 0 6.72386 0 7C0 7.27614 0.223858 7.5 0.5 7.5H2.02467C2.25912 9.86229 4.13773 11.741 6.5 11.9755V13.5C6.5 13.7761 6.72386 14 7 14C7.27614 14 7.5 13.7761 7.5 13.5V11.9755C9.86227 11.741 11.7409 9.86229 11.9753 7.5H13.4999C13.7761 7.5 13.9999 7.27614 13.9999 7C13.9999 6.72386 13.7761 6.5 13.4999 6.5H11.9753C11.7407 4.13788 9.86215 2.2594 7.5 2.02487V0.5ZM7 11.0002C4.79086 11.0002 3 9.20932 3 7.00018C3 4.79104 4.79086 3.00018 7 3.00018C9.20914 3.00018 11 4.79104 11 7.00018C11 9.20932 9.20914 11.0002 7 11.0002Z" fill="#06EFC5" />
                                    </svg> */}
                  <img
                    src={
                      UtilityFunctions.getImageURL() + "current-location.svg"
                    }
                    alt=""
                  />
                </button>
                <span className="input-group-text">
                  {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.5">
                                            <path d="M5.84328 4.56837C9.24369 1.16796 14.7568 1.16796 18.1573 4.56837C21.5577 7.96878 21.5577 13.4819 18.1573 16.8823L16.9703 18.0562C16.0954 18.9149 14.9603 20.0188 13.5644 21.3684C12.6922 22.2118 11.3084 22.2117 10.4362 21.3682L6.94515 17.9723C6.50638 17.5415 6.13912 17.1782 5.84328 16.8823C2.44287 13.4819 2.44287 7.96878 5.84328 4.56837ZM17.0966 5.62903C14.282 2.81441 9.71856 2.81441 6.90394 5.62903C4.08931 8.44366 4.08931 13.0071 6.90394 15.8217L8.39101 17.2891C9.20992 18.0906 10.2394 19.091 11.4791 20.29C11.7698 20.5711 12.231 20.5712 12.5218 20.2901L15.9167 16.9886C16.3856 16.5283 16.779 16.1393 17.0966 15.8217C19.9112 13.0071 19.9112 8.44366 17.0966 5.62903ZM12.0003 7.99903C13.658 7.99903 15.0018 9.34287 15.0018 11.0006C15.0018 12.6583 13.658 14.0021 12.0003 14.0021C10.3426 14.0021 8.99871 12.6583 8.99871 11.0006C8.99871 9.34287 10.3426 7.99903 12.0003 7.99903ZM12.0003 9.49903C11.171 9.49903 10.4987 10.1713 10.4987 11.0006C10.4987 11.8299 11.171 12.5021 12.0003 12.5021C12.8295 12.5021 13.5018 11.8299 13.5018 11.0006C13.5018 10.1713 12.8295 9.49903 12.0003 9.49903Z" fill="white" />
                                        </g>
                                    </svg> */}
                  {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.157 16.8822L16.97 18.0561C16.0952 18.9148 14.96 20.0187 13.5642 21.3683C12.6919 22.2117 11.3081 22.2115 10.436 21.3681L6.9449 17.9722C6.50614 17.5414 6.13887 17.1781 5.84303 16.8822C2.44262 13.4818 2.44262 7.96866 5.84303 4.56825C9.24344 1.16784 14.7566 1.16784 18.157 4.56825C21.5574 7.96866 21.5574 13.4818 18.157 16.8822ZM14.5002 10.9999C14.5002 9.61912 13.3808 8.49974 12 8.49974C10.6192 8.49974 9.49982 9.61912 9.49982 10.9999C9.49982 12.3808 10.6192 13.5001 12 13.5001C13.3808 13.5001 14.5002 12.3808 14.5002 10.9999Z" fill="#38BBF4" />
                                    </svg> */}
                  <img
                    src={
                      UtilityFunctions.getImageURL() + "location-icon-blank.svg"
                    }
                    alt=""
                  />
                  <img
                    src={
                      UtilityFunctions.getImageURL() + "location-icon-fill.svg"
                    }
                    alt=""
                  />
                </span>
              </div>
            </div>
            {/* TO DO: merge into one */}
            {secondaryAddresses.map((item, idx) => {
              return (
                <LocationsInput
                  secondaryAddresses={secondaryAddresses}
                  setSecondaryAddresses={setSecondaryAddresses}
                  value={secondaryAddresses?.[idx]?.address || ""}
                  key={idx + Date?.now()}
                  id={item}
                  handleAddressChange={(address) =>
                    handleAddressChange(address, idx)
                  }
                  handleDelete={(id, val) => handleDelete(id, idx, val)}
                  index={idx}
                  isSelectedRef={isSelectedRef}
                  refArray={refArray}
                  isSelectedRefArray={isSelectedRefArray}
                />
              );
            })}

            {secondaryAddresses.length <= 3 && (
              <button
                className="business-btn"
                type="button"
                onClick={addSecondary}
              >
                {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.5 2.75C8.5 2.33579 8.16421 2 7.75 2C7.33579 2 7 2.33579 7 2.75V7H2.75C2.33579 7 2 7.33579 2 7.75C2 8.16421 2.33579 8.5 2.75 8.5H7V12.75C7 13.1642 7.33579 13.5 7.75 13.5C8.16421 13.5 8.5 13.1642 8.5 12.75V8.5H12.75C13.1642 8.5 13.5 8.16421 13.5 7.75C13.5 7.33579 13.1642 7 12.75 7H8.5V2.75Z" fill="white" />
                            </svg> */}
                <img
                  src={
                    UtilityFunctions.getImageURL() + "add-plus-icon-white.svg"
                  }
                  alt=""
                />
                Add Secondary
              </button>
            )}
          </div>
        </div>
      </form>
      <div className="next-wrap">
        <button
          className={primaryAddress ? "cricle-next" : "cricle-next-inactive"}
          type="button"
          onClick={submitHandler}
        >
          <img
            alt=""
            src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
          />
        </button>
      </div>
    </Fragment>
  );
};

export default withRouter(BusinessLocation);
