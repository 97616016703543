import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { actionCreators, State } from "../../../store";
import { bindActionCreators } from "redux";

import { DatePicker } from "@blueprintjs/datetime";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/core/lib/css/blueprint.css";

import { UtilityFunctions } from "../../../utils/UtilityFunctions"
import ValidationErrorToast from "../../../components/validationErrorToast";
import moment from "moment";

const JobDescription = (props: any) => {
    const [date, setDate] = useState<any>(null);
    const [desc, setDesc] = useState<string>()
    const [isOpen, setIsOpen] = useState(false);
    const dateRef = useRef<any>();
    const descRef = useRef<any>();
    const dispatch = useDispatch();
    const state = useSelector((state: State) => state);
    const { setJobDate, setJobDesc } = bindActionCreators(actionCreators, dispatch);
    const [btnClick, setBtnClick] = useState(false);

    const dateFormatter = (date, days) => {
        var result = new Date(date);
        result?.setDate(result.getDate() + days);
        return result?.toISOString().substring(0, 10);
    }
    useEffect(() => {
        setDesc(state?.newJob?.description ? state?.newJob?.description : "")
        descRef.current.value = state.newJob.description ? state.newJob.description : ""
        props?.editJob && setDate(state?.newJob?.startFrom == null ? null : new Date(state?.newJob?.startFrom))
        props?.editJob && setJobDate(state?.newJob?.startFrom == null ? null : new Date(state?.newJob?.startFrom))
        dateRef.current.value = state?.newJob?.startFrom !== null ? moment(new Date(state?.newJob?.startFrom)).format("YYYY-MM-DD") : ""
    }, [])
    const onDateChangeHandler = (arg) => {
        if (arg != '') {
            ValidationErrorToast('Date Select', 'Please select a date from the dropdown calendar');
            dateRef.current.value = ''
            setIsOpen(true)
        }
    }
    const onDescChangeHandler = (input) => {
        setBtnClick(false)
        setDesc(input)
        if ((input.length === 0) || (input?.replace(/ /g, '').length <= 2500)) {
            setBtnClick(false)
        }
        else {
            setBtnClick(true)
        }
    }


    const submitHandler = (e) => {
        e.preventDefault();
        if (!dateRef.current.value) {
            setJobDate(null)
        }
        else {
            setJobDate(date ? date : state?.newJob?.startFrom)
        }
        if (desc?.replace(/ /g, '').length > 2500) {
            setBtnClick(true);
            ValidationErrorToast('Word Limit Exceeded', "Job description cannot contain more than 2500 letters/characters")
        }
        else if (desc?.length === 0) {
            setBtnClick(true);
            ValidationErrorToast('Job Description', "Please add job description before proceeding")
        }
        else {
            setJobDesc(desc)
            // console.log('Description Length', desc.replace(/ /g, '').length)
            props.nav.next()
        }
    }

    return <>
        <div className='top-divde-spa'>
            <span>
                <i
                    className="fa fa-angle-left text-white d-flex justify-content-between"
                    aria-hidden="true"
                    onClick={() => {
                        props?.nav?.back()
                    }}
                ></i>
            </span>
            <div className="step-content paragraph-spacin">
                <button type='button' disabled>
                    Job Description
                </button>
                <p>Enter your job description</p>
                <div className="step-text">
                    Step 4/8
                </div>
            </div>
        </div>
        <form onSubmit={submitHandler}>
            <div className="form-wrap">
                <div className="v-form-width">
                    <div className="form-floating mb-3">
                        <input type='text'
                            style={{ caretColor: 'transparent' }}
                            className='form-control'
                            placeholder="Start Date (optional)"
                            onClick={() => {
                                setIsOpen(prev => !prev)
                            }}
                            ref={dateRef}
                            onChange={e => onDateChangeHandler(e.target.value)}>
                        </input>
                        <label htmlFor="floatingInput">Start Date (optional)</label>
                        <div
                            style={{
                                display: `${isOpen ? "" : "none"}`,
                                background: 'inherit',
                                right: 0
                            }}>
                            <DatePicker
                                minDate={new Date()}
                                value={date}
                                onChange={e => {
                                    setDate(e)
                                }}
                                footerElement={
                                    <div className="colendbut d-flex">
                                        <button type='button'
                                            onClick={() => {
                                                setDate(null)
                                                dateRef.current.value = ''
                                                setIsOpen(prev => !prev)
                                            }
                                            }>
                                            Cancel
                                        </button>
                                        <button type='button' className="rightbut"
                                            onClick={e => {
                                                if (moment(date).format("YYYY-MM-DD") === 'Invalid date') {
                                                    dateRef.current.value = null
                                                }
                                                else {
                                                    dateRef.current.value = moment(date).format("YYYY-MM-DD");
                                                }
                                                setIsOpen(prev => !prev)
                                            }}>
                                            Confirm
                                        </button>
                                    </div>
                                }
                            />
                        </div>
                        <span className="input-group-text">
                            <img src={UtilityFunctions.getImageURL() + "calendar.svg"} alt="" />
                            <img src={UtilityFunctions.getImageURL() + "calendar-fill-blue.svg"} alt="" />
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.5">
                                    <path d="M17.75 3C19.5449 3 21 4.45507 21 6.25V17.75C21 19.5449 19.5449 21 17.75 21H6.25C4.45507 21 3 19.5449 3 17.75V6.25C3 4.45507 4.45507 3 6.25 3H17.75ZM19.5 8.5H4.5V17.75C4.5 18.7165 5.2835 19.5 6.25 19.5H17.75C18.7165 19.5 19.5 18.7165 19.5 17.75V8.5ZM7.75 14.5C8.44036 14.5 9 15.0596 9 15.75C9 16.4404 8.44036 17 7.75 17C7.05964 17 6.5 16.4404 6.5 15.75C6.5 15.0596 7.05964 14.5 7.75 14.5ZM12 14.5C12.6904 14.5 13.25 15.0596 13.25 15.75C13.25 16.4404 12.6904 17 12 17C11.3096 17 10.75 16.4404 10.75 15.75C10.75 15.0596 11.3096 14.5 12 14.5ZM7.75 10.5C8.44036 10.5 9 11.0596 9 11.75C9 12.4404 8.44036 13 7.75 13C7.05964 13 6.5 12.4404 6.5 11.75C6.5 11.0596 7.05964 10.5 7.75 10.5ZM12 10.5C12.6904 10.5 13.25 11.0596 13.25 11.75C13.25 12.4404 12.6904 13 12 13C11.3096 13 10.75 12.4404 10.75 11.75C10.75 11.0596 11.3096 10.5 12 10.5ZM16.25 10.5C16.9404 10.5 17.5 11.0596 17.5 11.75C17.5 12.4404 16.9404 13 16.25 13C15.5596 13 15 12.4404 15 11.75C15 11.0596 15.5596 10.5 16.25 10.5ZM17.75 4.5H6.25C5.2835 4.5 4.5 5.2835 4.5 6.25V7H19.5V6.25C19.5 5.2835 18.7165 4.5 17.75 4.5Z" fill="white" />
                                </g>
                            </svg> */}
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 8.5V17.75C21 19.5449 19.5449 21 17.75 21H6.25C4.45507 21 3 19.5449 3 17.75V8.5H21ZM7.25 15C6.55964 15 6 15.5596 6 16.25C6 16.9404 6.55964 17.5 7.25 17.5C7.94036 17.5 8.5 16.9404 8.5 16.25C8.5 15.5596 7.94036 15 7.25 15ZM12 15C11.3096 15 10.75 15.5596 10.75 16.25C10.75 16.9404 11.3096 17.5 12 17.5C12.6904 17.5 13.25 16.9404 13.25 16.25C13.25 15.5596 12.6904 15 12 15ZM7.25 10.5C6.55964 10.5 6 11.0596 6 11.75C6 12.4404 6.55964 13 7.25 13C7.94036 13 8.5 12.4404 8.5 11.75C8.5 11.0596 7.94036 10.5 7.25 10.5ZM12 10.5C11.3096 10.5 10.75 11.0596 10.75 11.75C10.75 12.4404 11.3096 13 12 13C12.6904 13 13.25 12.4404 13.25 11.75C13.25 11.0596 12.6904 10.5 12 10.5ZM16.75 10.5C16.0596 10.5 15.5 11.0596 15.5 11.75C15.5 12.4404 16.0596 13 16.75 13C17.4404 13 18 12.4404 18 11.75C18 11.0596 17.4404 10.5 16.75 10.5ZM17.75 3C19.5449 3 21 4.45507 21 6.25V7H3V6.25C3 4.45507 4.45507 3 6.25 3H17.75Z" fill="#38BBF4" />
                            </svg> */}
                        </span>
                    </div>
                    <div className="form-floating v-textarea-scorll mb-3">
                        <textarea
                            className={`form-control my-textarea ${btnClick ? 'error' : ""}`}
                            ref={descRef}
                            placeholder="Enter Job Description"
                            onChange={e => onDescChangeHandler(e.target.value)}
                        />
                        <label htmlFor="floatingInput">Enter Job Description</label>
                        <span className="input-group-text">
                            <img src={UtilityFunctions.getImageURL() + "edit.svg"} alt="" />
                            <img src={UtilityFunctions.getImageURL() + "Edit-fill-blue.svg"} alt="" />
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.5">
                                    <path d="M21.0312 2.96997C22.4286 4.36743 22.4286 6.63317 21.0312 8.03063L9.06283 19.999C8.78596 20.2758 8.44162 20.4757 8.06386 20.5787L2.94817 21.9739C2.38829 22.1266 1.87456 21.6128 2.02726 21.053L3.42244 15.9373C3.52547 15.5595 3.7253 15.2152 4.00217 14.9383L15.9705 2.96997C17.368 1.57251 19.6337 1.57251 21.0312 2.96997ZM15.001 6.06088L5.06283 15.999C4.97054 16.0913 4.90393 16.206 4.86959 16.332L3.81974 20.1814L7.66918 19.1315C7.7951 19.0972 7.90988 19.0306 8.00217 18.9383L17.94 8.99988L15.001 6.06088ZM17.0312 4.03063L16.061 4.99988L19 7.93988L19.9705 6.96997C20.7822 6.1583 20.7822 4.84231 19.9705 4.03063C19.1588 3.21896 17.8428 3.21896 17.0312 4.03063Z" fill="white" />
                                </g>
                            </svg> */}
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.5">
                                    <path d="M21.0312 2.96997C22.4286 4.36743 22.4286 6.63317 21.0312 8.03063L9.06283 19.999C8.78596 20.2758 8.44162 20.4757 8.06386 20.5787L2.94817 21.9739C2.38829 22.1266 1.87456 21.6128 2.02726 21.053L3.42244 15.9373C3.52547 15.5595 3.7253 15.2152 4.00217 14.9383L15.9705 2.96997C17.368 1.57251 19.6337 1.57251 21.0312 2.96997ZM15.001 6.06088L5.06283 15.999C4.97054 16.0913 4.90393 16.206 4.86959 16.332L3.81974 20.1814L7.66918 19.1315C7.7951 19.0972 7.90988 19.0306 8.00217 18.9383L17.94 8.99988L15.001 6.06088ZM17.0312 4.03063L16.061 4.99988L19 7.93988L19.9705 6.96997C20.7822 6.1583 20.7822 4.84231 19.9705 4.03063C19.1588 3.21896 17.8428 3.21896 17.0312 4.03063Z" fill="white" />
                                </g>
                            </svg> */}
                        </span>
                    </div>
                </div>
            </div>
        </form>
        <div className="next-wrap">
            <button className={(desc && desc?.replace(/ /g, '').length <= 2500) ? "cricle-next" : "cricle-next-inactive"}
                onClick={(e) => submitHandler(e)}>
                <img
                    alt=""
                    src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
                />
            </button>
        </div>
    </>
}

export default (JobDescription)