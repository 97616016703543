import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../../../store";
import { isEmpty } from 'lodash';
import { api } from "../../../middleware/api";
import InfiniteScroll from "react-infinite-scroll-component";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import { UtilityFunctions, modifiedSalaray, timeConvrter } from "../../../utils/UtilityFunctions";
const debounce = require("debounce-promise");
const Jobs = (props: any) => {
    const dispatch = useDispatch();
    const state = useSelector((state: State) => state);
    const { setSidebar, setJobsFetchState } = bindActionCreators(actionCreators, dispatch);
    const [finalJobList, setFinalJobList] = useState<any>();
    const scrollRef = useRef<any>()


    useEffect(() => {
        setFinalJobList(state.jobsList)
    }, [state.jobsList])

    const renderJobs = finalJobList?.map(job => {
        return <div className="job-list-wrap" data-bs-toggle="modal" data-bs-target="#staticBackdrop" key={job.id} onClick={() => {
            if (props?.userType === STRING_CONSTANTS.userType.employer) {
                setSidebar({ sidebar_name: 'job-details', show_sidebar: true });
                props.setSelectedJob(job);
            } else {
                // for employee login
                setSidebar({ sidebar_name: 'employee-details', show_sidebar: true });
                // props.setSelectedJob(job);
                props?.setSelectedEmployee(job);
            }
        }}>
            <div className="jb-profl-lft">
                <div className="job-pic"><img alt="" src={job?.cover ? job?.cover : UtilityFunctions.getImageURL() + "bildeing-squre-img.png"} />
                </div>
                <div className="job-name">
                    <h3>{job.title}</h3>
                    <p>
                        <span>
                            {job?.location?.suburb}
                        </span>
                        &nbsp;
                        {/* <svg width="2" height="2" viewBox="0 0 2 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.6" cx="1" cy="1" r="1" fill="white" />
                        </svg> */}
                        <img src={UtilityFunctions.getImageURL() + "svg-2px-dot.svg"} alt="" />
                        <span>
                            {modifiedSalaray(job.minSalary, job.maxSalary, null, job?.salaryCheckoutType)}
                        </span>
                        &nbsp;
                        {/* <svg width="2" height="2" viewBox="0 0 2 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.6" cx="1" cy="1" r="1" fill="white" />
                        </svg> */}
                        <img src={UtilityFunctions.getImageURL() + "svg-2px-dot.svg"} alt="" />
                        &nbsp;
                        {UtilityFunctions.convertMonthsToYearsAndMonths(job?.experience)}
                        {/* {(timeConvrter(job?.experience).year > 0 && timeConvrter(job?.experience).month > 0
                            ? `${timeConvrter(job?.experience).year} Yr ${timeConvrter(job?.experience).month
                            } Mo`
                            : '') +
                            (timeConvrter(job?.experience).year > 0 && timeConvrter(job?.experience).month == 0
                                ? `${timeConvrter(job?.experience).year} Yr`
                                : '') +
                            (timeConvrter(job?.experience).year == 0 && timeConvrter(job?.experience).month >= 0
                                ? `${timeConvrter(job?.experience).month} Mo`
                                : '')} */}

                        {/* <svg width="2" height="2" viewBox="0 0 2 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.6" cx="1" cy="1" r="1" fill="white" />
                        </svg>
                        &nbsp;
                        <span>
                            {job?.category?.name}
                        </span> */}
                        {/* &nbsp;
                        <svg width="2" height="2" viewBox="0 0 2 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.6" cx="1" cy="1" r="1" fill="white" />
                        </svg> */}
                        {/* &nbsp;
                        <span>
                            {job?.subCategory?.name}
                        </span> */}
                    </p>
                </div>
            </div>
            {props?.userType === STRING_CONSTANTS.userType.employer ? <div className="jb-allocat-rgt text-end">
                {job?.status === 'active' ?
                    <p className="allocated-green">
                        <img alt="" src={UtilityFunctions.getImageURL() + "allocated-green.svg"} />
                        Active
                    </p> : job?.status === 'allocated' ?
                        <p className="allocated-yellow">
                            <img alt="" src={UtilityFunctions.getImageURL() + "allocated-yellow.svg"} />
                            Allocated
                        </p> : job?.status === 'cancelled' ?
                            <p className="canceled-pink">
                                <img alt="" src={UtilityFunctions.getImageURL() + "canceled-pink.svg"} />
                                Cancelled
                            </p> : job?.status === 'completed' ?
                                <p className="completed-blue">
                                    <img alt="" src={UtilityFunctions.getImageURL() + "completed-blue.svg"} />
                                    Completed
                                </p> : null}
                <span>{job?.type === 'fullTime' ? 'Full Time' : job?.type === 'partTime' ? 'Part Time' : job?.type === 'casual' ? 'Casual' : 'None'}</span>
            </div> :
                <div className="jb-allocat-rgt text-end">
                    <p className="trading-name">
                        <div className='trading-name-he'>{job?.employer?.tradingName}</div>
                        <img alt="" className="top-0" src={job?.employer?.logo ? job?.employer?.logo : UtilityFunctions.getImageURL() + "bildeing-squre-img.png"} />
                    </p>
                    <span style={{ opacity: 1 }}>{job?.type === 'fullTime' ? 'Full Time' : job?.type === 'partTime' ? 'Part Time' : job?.type === 'casual' ? 'Casual' : 'None'}</span>
                </div>
            }
        </div>
    })

    const debounceClick = debounce((value: string) => {
        setJobsFetchState(state.jobFilterSearch.offset + 10)
      }, 800);

    return (<>
        {props?.userType === STRING_CONSTANTS.userType.employee && <h5 className="jobTitle">All Jobs</h5>}
        {!isEmpty(state.jobsList) ?
            <div className="employe-wrap">
                <div className="job-list-wrap-scroll">
                    <div id="scrollableDiv2" className="jobs-scrollable" ref={scrollRef}>
                        {props?.userType !== STRING_CONSTANTS.userType.employer ?
                            <InfiniteScroll
                                dataLength={state.jobsList.length}
                                next={() => {
                                    debounceClick()
                                }}
                                hasMore={props?.hasMore}

                                //style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                                loader={<h4>Loading...</h4>}
                                scrollableTarget="scrollableDiv2"
                                scrollThreshold={1}
                            >
                                {renderJobs}
                            </InfiniteScroll> : renderJobs}
                    </div>
                </div>
            </div> :
            <>
                {props?.userType === STRING_CONSTANTS.userType.employer ?
                    <div className="no-table-data no-table-one">
                        <div>
                            <div className="arrow-inner">
                                <img src={UtilityFunctions.getImageURL() + 'pointing-arrow.png'} alt="" />
                            </div>
                            <div>
                                <img src={UtilityFunctions.getImageURL() + 'no-data.svg'} alt="" />
                                <p>It looks like you haven’t listed any jobs yet. Tap the plus to create your first job listing.</p>
                            </div>
                        </div>
                    </div> :
                    <div className="no-table-data no-table-one justify-content-center">
                        <div>
                            <div>
                                <img src={UtilityFunctions.getImageURL() + 'no-data.svg'} alt="" />
                                <p>No results found.</p>
                            </div>
                        </div>
                    </div>
                }

            </>
        }
    </>)
}

export default Jobs;