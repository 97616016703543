import { useEffect, useState } from "react";
import React from "react";

import ProfileWrapper from "../../common/Profile-Wrapper";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { checkData } from "../../utils/checkData";
import ABNValidation from "./ABNValidation";
import BusinessDetails from "./BusinessDetails";
import BusinessLocation from "./BusinessLocation";
import BusinessContact from "./BusinessContact";
import Completion from "./Completion";
import { api } from "../../middleware/api";
import { cookieService, userService } from "../../_services";
import { userData } from "../../utils/UserData";
import { Data } from "../../_services/collections";

const EmployerProfileCompletion = (props: any) => {
    const [step, setStep] = useState(props?.location?.state?.step == 3 ? 3 : 1);
    const userData = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)
    useEffect(() => {
        checkData(props, STRING_CONSTANTS.navigationString.login, null);
    })

    useEffect(() => {
        const user = cookieService.getCookie(STRING_CONSTANTS.cookieStrings?.user) as Data
        user.user.isProfileCompleted &&
            !props?.location?.state?.updateAddress &&
            props?.history?.push(STRING_CONSTANTS.navigationString.dashboard.home)
    }, [])

    useEffect(() => {
        if (!userData?.user?.isProfileCompleted) {
            userService.getUserData()
                .then((res) => {
                    cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res)
                })
        }
    }, [step])

    const navigation = {
        next: function () {
            if (step !== 5) {
                setStep(prevState => prevState + 1)
            }
        },
        back: function () {
            if (step !== 1) {
                setStep(prevState => prevState - 1)
            }
        }
    }

    return (<>
        <div style={{ position: 'absolute' }}>
        </div>
        <ProfileWrapper history={props.history} nav={navigation} currentStep={step} >
            {step === 1 ? <ABNValidation nav={navigation} /> : null}
            {step === 2 ? <BusinessDetails nav={navigation} /> : null}
            {step === 3 ? <BusinessLocation nav={navigation} updateAddress={props?.location?.state?.updateAddress} /> : null}
            {step === 4 ? <BusinessContact nav={navigation} /> : null}
            {step === 5 ? <Completion nav={navigation} history={props.history} /> : null}
        </ProfileWrapper>
    </>
    )

}

export default EmployerProfileCompletion;