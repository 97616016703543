import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import Logout from "../../components/logout";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { dataURLtoFile, UtilityFunctions } from "../../utils/UtilityFunctions";
import ImageCrop from "../../common/ui/ImageCrop";
import { DatePicker } from "@blueprintjs/datetime";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import ValidationErrorToast from "../../components/validationErrorToast";
import moment from "moment";
import GuardianEmail from "../../components/guardianEmail"
import { cookieService, userService } from "../../_services";

const Introduction = (props) => {
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("")
  const [about, setAbout] = useState("")
  const [DOB, setDOB] = useState(new Date())
  const dateRef = useRef(null);

  //FOR GUARDIAN EMAIL MODAL
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [mailAddress, setMailAddress] = useState();

  //FOR HANDLING ERROR STATES
  const initialErrorState = { name: false, dob: false, about: false }
  const [errorState, setErrorState] = useState(initialErrorState)

  useEffect(() => { props?.userData?.user?.isProfileCompleted && props.setStep(8) }, [props])

  useEffect(() => {
    if (props?.userData) {
      setName(props?.userData?.user?.firstName ? (props?.userData?.user?.firstName + " " + props?.userData?.user?.lastName) : "")
      props?.userData?.dob && setDOB(new Date(props?.userData?.dob))
      dateRef.current.value = props?.userData?.dob ? moment(props?.userData?.dob).format("YYYY-MM-DD") : ""
      props?.userData?.profilePhoto && setPreview(props?.userData?.profilePhoto)
      setAbout(props?.userData?.about ? props?.userData?.about : "")
    }
  }, [props])

  const onDateChangeHandler = (arg) => {
    if (arg != '') {
      ValidationErrorToast('Date Select', 'Please select a date from the dropdown calendar');
      dateRef.current.value = ''
      setIsOpen(true)
    }
  }

  const onDateConfirmHandler = () => {
    if (moment(DOB).format("YYYY-MM-DD") === 'Invalid date') {
      dateRef.current.value = null
    }
    else {
      dateRef.current.value = moment(DOB).format("YYYY-MM-DD");
    }
    setIsOpen(prev => !prev)
  }

  const inputValidator = (e) => {
    // e.preventDefault();
    if (!name) {
      ValidationErrorToast("Full Name", "This field cannot be empty!")
      setErrorState(prev => { return { ...prev, name: true } })
    }
    else if (!DOB || !dateRef.current.value) {
      ValidationErrorToast("Date of birth", "This field cannot be empty!")
      setErrorState(prev => { return { ...prev, dob: true } })
    }
    else if (!about) {
      ValidationErrorToast("About", "This field cannot be empty!")
      setErrorState(prev => { return { ...prev, about: true } })
    }
    else if (about?.replace(/ /g, '').length > 2500) {
      ValidationErrorToast('Word Limit Exceeded', "This field cannot contain more than 2500 letters/characters")
      setErrorState(prev => { return { ...prev, about: true } })
    }
    else if (moment().diff(DOB, "years") < 18) {
      setShowEmailModal(true)
    }
    else dataSubmitHandler();
  }

  const dataSubmitHandler = () => {
    const params = {
      user: {
        firstName: UtilityFunctions.nameFormatter(name)[0],
        lastName: UtilityFunctions.nameFormatter(name)[1],
      },
      dob: moment(DOB).format("YYYY-MM-DD"),
      about: about
    }
    userService.userProfile(params)
      .then(res => {
        cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res)
      })
      .then(data => {
        if (preview && !UtilityFunctions.isValidURL(preview)) {
          let formData = new FormData()
          formData.append("profilePhoto", dataURLtoFile(preview, Date.now() + '.jpeg'))
          userService.userProfile(formData)
            .then(res => {
              cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res)
            })
            .then(() => props.nav.next())
            .catch(e => ValidationErrorToast(e[0], e[1]))

        }
        else if (preview === null) {
          userService.userProfile({ profilePhoto: null })
            .then(res => props.nav.next())
            .catch(e => { })
        }
        else props.nav.next()
      })
      .catch(e => ValidationErrorToast(e[0], e[1]))
  }

  return (
    <>
      <div className='top-divde-spa'>
        <span>
          <i
            className="text-white d-flex justify-content-between"
            aria-hidden="true"
            onClick={() => setShow(true)}
          >
            <img src={UtilityFunctions.getImageURL() + "close-icon.svg"} width={'24px'} height={'24px'} alt="" />
            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.2097 4.3871L4.29289 4.29289C4.65338 3.93241 5.22061 3.90468 5.6129 4.2097L5.70711 4.29289L12 10.585L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L13.415 12L19.7071 18.2929C20.0676 18.6534 20.0953 19.2206 19.7903 19.6129L19.7071 19.7071C19.3466 20.0676 18.7794 20.0953 18.3871 19.7903L18.2929 19.7071L12 13.415L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L10.585 12L4.29289 5.70711C3.93241 5.34662 3.90468 4.77939 4.2097 4.3871L4.29289 4.29289L4.2097 4.3871Z" fill="white" />
            </svg> */}
          </i>
        </span>
        <Logout
          show={show}
          handleClose={() => setShow(false)}
        />
        <div className="step-content signup-mar">
          <button type='button' disabled>
            {STRING_CONSTANTS.employeeProfileCompletionPages.step1.title}
          </button>
          <p>
            {STRING_CONSTANTS.employeeProfileCompletionPages.step1.subtitle}
          </p>
          <div className="step-text">
            {STRING_CONSTANTS.employeeProfileCompletionPages.step1.step}
          </div>
        </div>
      </div>
      <form
        onSubmit={inputValidator}
      >
        <div className="form-wrap business-detailss">
          <div className='v-form-width'>
            <div className="update-image">
              <div className='upload-image '>
                <div className='file-thumb' >
                  {(selectedFile || preview) ?
                    <div className="crossed-icon">
                      <img alt="Selected Image" className="image-show" src={UtilityFunctions.getImageURL() + "cross-icon.svg"}
                        onClick={(e) => { setSelectedFile(null); setPreview(null) }}
                      />
                      <img alt="Preview" className="image-show" src={preview} />
                    </div> :
                    <div>
                      <ImageCrop
                        ratio={1 / 1}
                        croppedImage={(imgSrc, file) => {
                          setSelectedFile(file)
                          setPreview(imgSrc);
                        }}
                        size={25}
                      />
                      <label htmlFor="image_id">
                        {/* <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.82869 0.206923L9.94531 0.200195C10.4581 0.200195 10.8808 0.586236 10.9386 1.08357L10.9453 1.2002V8.2002H17.9453C18.4581 8.2002 18.8808 8.58624 18.9386 9.08357L18.9453 9.2002C18.9453 9.71303 18.5593 10.1357 18.0619 10.1935L17.9453 10.2002H10.9453V17.2002C10.9453 17.713 10.5593 18.1357 10.0619 18.1935L9.94531 18.2002C9.43248 18.2002 9.00981 17.8142 8.95204 17.3168L8.94531 17.2002V10.2002H1.94531C1.43248 10.2002 1.00981 9.81415 0.95204 9.31682L0.945312 9.2002C0.945312 8.68736 1.33135 8.26469 1.82869 8.20692L1.94531 8.2002H8.94531V1.2002C8.94531 0.687359 9.33135 0.264688 9.82869 0.206923L9.94531 0.200195L9.82869 0.206923Z" fill="#38BBF4"></path>
                        </svg> */}
                        <img src={UtilityFunctions.getImageURL() + "add_img.svg"} className="plus-icon" alt="" />
                        <p>
                          {STRING_CONSTANTS.employeeProfileCompletionPages.step1.upload_image}
                        </p>
                      </label></div>}
                </div>
                <ImageCrop
                  ratio={16 / 9}
                  croppedImage={(imgSrc, file) => {
                    setSelectedFile(file)
                    setPreview(imgSrc);
                  }}
                  size={25}
                />
              </div>
            </div>
            <div className="form-floating mb-3">
              <input type='text' className={`form-control ${errorState?.name ? 'error' : ""}`}
                placeholder={STRING_CONSTANTS.employeeProfileCompletionPages.step1.enter_full_name}
                value={name}
                onChange={(e) => {
                  setErrorState(prev => { return { ...prev, name: false } })
                  setName(e.target.value);
                }}
              />
              <label htmlFor="floatingInput">
                {STRING_CONSTANTS.employeeProfileCompletionPages.step1.enter_full_name}
              </label>
              <span className="input-group-text">
                <img src={UtilityFunctions.getImageURL() + "input-user-blank.svg"} alt="" />
                <img src={UtilityFunctions.getImageURL() + "input-user-fill-blue.svg"} alt="" />
                {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.5">
                    <path d="M17.7545 13.9997C18.9966 13.9997 20.0034 15.0065 20.0034 16.2486V16.824C20.0034 17.7183 19.6838 18.5831 19.1023 19.2625C17.5329 21.096 15.1457 22.0008 12.0004 22.0008C8.8545 22.0008 6.46849 21.0957 4.90219 19.2614C4.32242 18.5825 4.00391 17.719 4.00391 16.8262V16.2486C4.00391 15.0065 5.01076 13.9997 6.25278 13.9997H17.7545ZM17.7545 15.4997H6.25278C5.83919 15.4997 5.50391 15.835 5.50391 16.2486V16.8262C5.50391 17.3619 5.69502 17.88 6.04287 18.2874C7.29618 19.755 9.26206 20.5008 12.0004 20.5008C14.7387 20.5008 16.7063 19.755 17.9627 18.2871C18.3117 17.8795 18.5034 17.3605 18.5034 16.824V16.2486C18.5034 15.835 18.1681 15.4997 17.7545 15.4997ZM12.0004 2.00439C14.7618 2.00439 17.0004 4.24297 17.0004 7.00439C17.0004 9.76582 14.7618 12.0044 12.0004 12.0044C9.23894 12.0044 7.00036 9.76582 7.00036 7.00439C7.00036 4.24297 9.23894 2.00439 12.0004 2.00439ZM12.0004 3.50439C10.0674 3.50439 8.50036 5.0714 8.50036 7.00439C8.50036 8.93739 10.0674 10.5044 12.0004 10.5044C13.9334 10.5044 15.5004 8.93739 15.5004 7.00439C15.5004 5.0714 13.9334 3.50439 12.0004 3.50439Z" fill="white" />
                  </g>
                </svg> */}
                {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.7543 13.9999C18.9963 13.9999 20.0032 15.0068 20.0032 16.2488V17.1673C20.0032 17.7406 19.8239 18.2997 19.4906 18.7662C17.9447 20.9294 15.4204 22.0011 12.0001 22.0011C8.57915 22.0011 6.05619 20.9289 4.51403 18.7646C4.18207 18.2987 4.00366 17.7409 4.00366 17.1688V16.2488C4.00366 15.0068 5.01052 13.9999 6.25254 13.9999H17.7543ZM12.0001 2.00464C14.7615 2.00464 17.0001 4.24321 17.0001 7.00464C17.0001 9.76606 14.7615 12.0046 12.0001 12.0046C9.2387 12.0046 7.00012 9.76606 7.00012 7.00464C7.00012 4.24321 9.2387 2.00464 12.0001 2.00464Z" fill="#38BBF4" />
                </svg> */}
              </span>
            </div>
            <div className="form-floating mb-3">
              <input type='text'
                style={{ caretColor: 'transparent' }}
                className={`form-control ${errorState?.dob ? 'error' : ""}`}
                placeholder="Enter DOB"
                onClick={() => {
                  setIsOpen(prev => !prev)
                  setErrorState(prev => { return { ...prev, dob: false } })
                }}
                ref={dateRef}
                onChange={e => {
                  // setErrorState(prev => { return { ...prev, dob: false } })
                  onDateChangeHandler(e.target.value)
                }}
              >
              </input>
              <label htmlFor="floatingInput">
                {STRING_CONSTANTS.employeeProfileCompletionPages.step1.enter_dob}
              </label>
              <div
                style={{
                  display: `${isOpen ? "" : "none"}`,
                  background: 'inherit',
                  right: 0
                }}>
                <DatePicker
                  minDate={new Date("1950-01-01")}
                  maxDate={new Date()}
                  value={DOB}
                  onChange={e => {
                    setDOB(e)
                  }}
                  footerElement={
                    <div className="colendbut d-flex">
                      <button type='button'
                        onClick={() => {
                          setDOB(null)
                          dateRef.current.value = ''
                          setIsOpen(prev => !prev)
                        }
                        }>
                        Cancel
                      </button>
                      <button type='button' className="rightbut"
                        onClick={e => onDateConfirmHandler()}>
                        Confirm
                      </button>
                    </div>
                  }
                />
              </div>
              <span className="input-group-text">
                <img src={UtilityFunctions.getImageURL() + "calendar.svg"} alt="" />
                <img src={UtilityFunctions.getImageURL() + "calendar-fill-blue.svg"} alt="" />
                {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.5">
                    <path d="M17.75 3C19.5449 3 21 4.45507 21 6.25V17.75C21 19.5449 19.5449 21 17.75 21H6.25C4.45507 21 3 19.5449 3 17.75V6.25C3 4.45507 4.45507 3 6.25 3H17.75ZM19.5 8.5H4.5V17.75C4.5 18.7165 5.2835 19.5 6.25 19.5H17.75C18.7165 19.5 19.5 18.7165 19.5 17.75V8.5ZM7.75 14.5C8.44036 14.5 9 15.0596 9 15.75C9 16.4404 8.44036 17 7.75 17C7.05964 17 6.5 16.4404 6.5 15.75C6.5 15.0596 7.05964 14.5 7.75 14.5ZM12 14.5C12.6904 14.5 13.25 15.0596 13.25 15.75C13.25 16.4404 12.6904 17 12 17C11.3096 17 10.75 16.4404 10.75 15.75C10.75 15.0596 11.3096 14.5 12 14.5ZM7.75 10.5C8.44036 10.5 9 11.0596 9 11.75C9 12.4404 8.44036 13 7.75 13C7.05964 13 6.5 12.4404 6.5 11.75C6.5 11.0596 7.05964 10.5 7.75 10.5ZM12 10.5C12.6904 10.5 13.25 11.0596 13.25 11.75C13.25 12.4404 12.6904 13 12 13C11.3096 13 10.75 12.4404 10.75 11.75C10.75 11.0596 11.3096 10.5 12 10.5ZM16.25 10.5C16.9404 10.5 17.5 11.0596 17.5 11.75C17.5 12.4404 16.9404 13 16.25 13C15.5596 13 15 12.4404 15 11.75C15 11.0596 15.5596 10.5 16.25 10.5ZM17.75 4.5H6.25C5.2835 4.5 4.5 5.2835 4.5 6.25V7H19.5V6.25C19.5 5.2835 18.7165 4.5 17.75 4.5Z" fill="white" />
                  </g>
                </svg> */}
                {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21 8.5V17.75C21 19.5449 19.5449 21 17.75 21H6.25C4.45507 21 3 19.5449 3 17.75V8.5H21ZM7.25 15C6.55964 15 6 15.5596 6 16.25C6 16.9404 6.55964 17.5 7.25 17.5C7.94036 17.5 8.5 16.9404 8.5 16.25C8.5 15.5596 7.94036 15 7.25 15ZM12 15C11.3096 15 10.75 15.5596 10.75 16.25C10.75 16.9404 11.3096 17.5 12 17.5C12.6904 17.5 13.25 16.9404 13.25 16.25C13.25 15.5596 12.6904 15 12 15ZM7.25 10.5C6.55964 10.5 6 11.0596 6 11.75C6 12.4404 6.55964 13 7.25 13C7.94036 13 8.5 12.4404 8.5 11.75C8.5 11.0596 7.94036 10.5 7.25 10.5ZM12 10.5C11.3096 10.5 10.75 11.0596 10.75 11.75C10.75 12.4404 11.3096 13 12 13C12.6904 13 13.25 12.4404 13.25 11.75C13.25 11.0596 12.6904 10.5 12 10.5ZM16.75 10.5C16.0596 10.5 15.5 11.0596 15.5 11.75C15.5 12.4404 16.0596 13 16.75 13C17.4404 13 18 12.4404 18 11.75C18 11.0596 17.4404 10.5 16.75 10.5ZM17.75 3C19.5449 3 21 4.45507 21 6.25V7H3V6.25C3 4.45507 4.45507 3 6.25 3H17.75Z" fill="#38BBF4" />
                </svg> */}
              </span>
            </div>
            <div className="form-floating v-textarea-scorll mb-3">
              <textarea
                className={`form-control my-textarea ${errorState?.about ? 'error' : ""}`}
                value={about}
                placeholder={STRING_CONSTANTS.employeeProfileCompletionPages.step1.enter_about_me}
                onChange={e => {
                  setErrorState(prev => { return { ...prev, about: false } })
                  setAbout(e.target.value)
                }}
              />
              <label htmlFor="floatingInput">
                {STRING_CONSTANTS.employeeProfileCompletionPages.step1.enter_about_me}
              </label>
              <span className="input-group-text">
                <img src={UtilityFunctions.getImageURL() + "edit.svg"} alt="" />
                <img src={UtilityFunctions.getImageURL() + "Edit-fill-blue.svg"} alt="" />
                {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.5">
                    <path d="M21.0312 2.96997C22.4286 4.36743 22.4286 6.63317 21.0312 8.03063L9.06283 19.999C8.78596 20.2758 8.44162 20.4757 8.06386 20.5787L2.94817 21.9739C2.38829 22.1266 1.87456 21.6128 2.02726 21.053L3.42244 15.9373C3.52547 15.5595 3.7253 15.2152 4.00217 14.9383L15.9705 2.96997C17.368 1.57251 19.6337 1.57251 21.0312 2.96997ZM15.001 6.06088L5.06283 15.999C4.97054 16.0913 4.90393 16.206 4.86959 16.332L3.81974 20.1814L7.66918 19.1315C7.7951 19.0972 7.90988 19.0306 8.00217 18.9383L17.94 8.99988L15.001 6.06088ZM17.0312 4.03063L16.061 4.99988L19 7.93988L19.9705 6.96997C20.7822 6.1583 20.7822 4.84231 19.9705 4.03063C19.1588 3.21896 17.8428 3.21896 17.0312 4.03063Z" fill="white" />
                  </g>
                </svg> */}
                {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.5">
                    <path d="M21.0312 2.96997C22.4286 4.36743 22.4286 6.63317 21.0312 8.03063L9.06283 19.999C8.78596 20.2758 8.44162 20.4757 8.06386 20.5787L2.94817 21.9739C2.38829 22.1266 1.87456 21.6128 2.02726 21.053L3.42244 15.9373C3.52547 15.5595 3.7253 15.2152 4.00217 14.9383L15.9705 2.96997C17.368 1.57251 19.6337 1.57251 21.0312 2.96997ZM15.001 6.06088L5.06283 15.999C4.97054 16.0913 4.90393 16.206 4.86959 16.332L3.81974 20.1814L7.66918 19.1315C7.7951 19.0972 7.90988 19.0306 8.00217 18.9383L17.94 8.99988L15.001 6.06088ZM17.0312 4.03063L16.061 4.99988L19 7.93988L19.9705 6.96997C20.7822 6.1583 20.7822 4.84231 19.9705 4.03063C19.1588 3.21896 17.8428 3.21896 17.0312 4.03063Z" fill="white" />
                  </g>
                </svg> */}
              </span>
            </div>
          </div>
        </div>
      </form>
      <div className="next-wrap">
        <button className={
          (DOB && name && about) ?
            "cricle-next"
            : "cricle-next-inactive"
        }
          onClick={(e) => inputValidator(e)}
        >
          <img
            alt=""
            src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
          />
        </button>
      </div>
      <GuardianEmail
        show={showEmailModal}
        handleClose={() => {
          // setDOB(null);
          // dateRef.current.value = null;
          setShowEmailModal(false)
        }}
        dataSubmitHandler={dataSubmitHandler}
        mailAddress={mailAddress}
        setMailAddress={setMailAddress}
      />
    </>
  )
}

export default withRouter(Introduction)