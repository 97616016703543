import { withRouter } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { UtilityFunctions } from "../../utils/UtilityFunctions"
import ValidationErrorToast from "../../components/validationErrorToast";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { cookieService, userService } from "../../_services";
import SuccessToast from "../../components/successToast";

const Salary = (props) => {
  const [minValue, setMinValue] = useState<any>("$");
  const [maxValue, setMaxValue] = useState<any>("$");
  const [type, setType] = useState("fullTime");
  const [checkout, setCheckout] = useState("monthly")
  const [show, setShow] = useState(false);//Max Salary Input render conditionally

  const initialState = { min: false, max: false };
  const [errorState, setErrorState] = useState(initialState);

  const isProfileBeingUpdated = props?.location?.state?.updateProfile || false;

  // Setting up backend data inside inputs automatically
  useEffect(() => {
    if (props?.userData) {
      const minSal = props?.userData?.minSalary;
      const maxSal = props?.userData?.maxSalary;
      const type = props?.userData?.jobType;
      const checkout = props?.userData?.salaryCheckoutType;
      if (minSal > 0) {
        setMinValue("$" + Math.round(minSal).toLocaleString())
      }
      if (parseInt(maxSal)) {
        setMaxValue("$" + Math.round(maxSal).toLocaleString())
        setShow(true)
      }
      type && setType(type)
      checkout && setCheckout(checkout)
    }
  }, [props?.userData])

  const minSalaryFormatter = (e) => {
    setMinValue("$" + `${e?.target?.value?.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`)
  }

  const maxSalaryFormatter = (e) => {
    setMaxValue("$" + `${e?.target?.value?.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`)
  }

  const submitHandler = (e) => {
    e.preventDefault();
    if (!parseFloat(`${minValue}`?.replace(/[^\d\.]/g, ''))) {
      ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_salary_range, STRING_CONSTANTS.validationString.req_salary_empty)
      setErrorState(prev => { return { ...prev, min: true } })
    }
    else if (show && parseFloat(`${minValue}`?.replace(/[^\d\.]/g, '')) >= (parseFloat(`${maxValue}`?.replace(/[^\d\.]/g, '')) || 0)) {
      ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_salary_range, STRING_CONSTANTS.validationString.req_salary_valid)
      setErrorState(prev => { return { ...prev, max: true } })
    }
    else {
      const salaryObject = {
        salaryCheckoutType: checkout,
        jobType: type,
        minSalary: parseFloat(`${minValue}`?.replace(/[^\d\.]/g, '')) ? parseFloat(`${minValue}`?.replace(/[^\d\.]/g, '')) : 0,
        maxSalary: parseFloat(`${maxValue}`?.replace(/[^\d\.]/g, '')) ? parseFloat(`${maxValue}`?.replace(/[^\d\.]/g, '')) : 0,
        user: {
          isProfileCompleted: true
        }
      }
      userService.userProfile(salaryObject)
        .then(res =>
          cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res.data)
        )
        .then(() => {
          if (isProfileBeingUpdated) {
            props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "updateProfile" })
            SuccessToast("Success!", "Your profile details have been updated successfully")
          }
          else props.nav.next()
        })
        .catch((e) => ValidationErrorToast(e[0], e[1]))
    };
  }

  return <>
    <div className='top-divde-spa'>
      <span>
        <i
          className="fa fa-angle-left text-white d-flex justify-content-between"
          aria-hidden="true"
          onClick={() => {
            isProfileBeingUpdated ?
              props.history.push("/dashboard/settings/", { tab: "updateProfile" }) :
              props?.nav?.back()
          }}
        >
        </i>
      </span>
      <div className="step-content paragraph-spacin">
        <button type='button' disabled>
          {STRING_CONSTANTS.employeeProfileCompletionPages.step7.title}
        </button>
        <p>
          {STRING_CONSTANTS.employeeProfileCompletionPages.step7.subtitle}
        </p>
        <div className="step-text">
          {STRING_CONSTANTS.employeeProfileCompletionPages.step7.step}
        </div>
      </div>
    </div>
    <form onSubmit={submitHandler}>
      <div className="form-wrap jobsalary-spactop">
        <div className="v-form-width">
          <div className="form-floating1 mb-3">
            <div className="v-top-timeing-part">
              <button type='button' className={type === "fullTime" ? "active" : type === "fullTime" ? "active" : ""}
                onClick={() => {
                  setType('fullTime')
                }}>Full Time</button>
              <button type='button' className={type === "partTime" ? "active" : type === "partTime" ? "active" : ""}
                onClick={() => {
                  setType('partTime')
                }}>Part Time</button>
              <button type='button' className={type === "casual" ? "active" : type === "casual" ? "active" : ""}
                onClick={() => {
                  setType('casual')
                }}>Casual</button>
            </div>
            <div className="v-working-set">
              <button type='button' className={checkout === "hourly" ? "active" : checkout === "hourly" ? "active" : ""}
                onClick={() => {
                  setCheckout("hourly")
                }}>Hourly</button>
              <button type='button' className={checkout === "daily" ? "active" : checkout === "daily" ? "active" : ""}
                onClick={() => {
                  setCheckout("daily")
                }}>Daily</button>
              <button type='button' className={checkout === "weekly" ? "active" : checkout === "weekly" ? "active" : ""}
                onClick={() => {
                  setCheckout("weekly")
                }}>Weekly</button>
              <button type='button' className={checkout === "monthly" ? "active" : checkout === "monthly" ? "active" : ""}
                onClick={() => {
                  setCheckout("monthly")
                }}>Monthly</button>
              <button type='button' className={checkout === "yearly" ? "active" : checkout === "yearly" ? "active" : ""}
                onClick={() => {
                  setCheckout("yearly")
                }}>Yearly</button>
            </div>
            <div className="jobsalary-girdset mt-2">
              <div className="form-floating mb-3">
                <input type='text'
                  maxLength={11}
                  className={`form-control `}
                  placeholder={STRING_CONSTANTS.employeeProfileCompletionPages.step7.start_value}
                  onChange={(e) => minSalaryFormatter(e)}
                  value={minValue}
                />
                <label htmlFor="floatingInput">{STRING_CONSTANTS.employeeProfileCompletionPages.step7.start_value}</label>
                <span className="input-group-text">
                  <img src={UtilityFunctions.getImageURL() + "money-fill-gray.svg"} alt="" />
                  <img src={UtilityFunctions.getImageURL() + "money-fill-blue.svg"} alt="" />
                  {/* <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                      <path d="M10.5 8.75C8.84315 8.75 7.5 10.0931 7.5 11.75C7.5 13.4069 8.84315 14.75 10.5 14.75C12.1569 14.75 13.5 13.4069 13.5 11.75C13.5 10.0931 12.1569 8.75 10.5 8.75ZM9 11.75C9 10.9216 9.67157 10.25 10.5 10.25C11.3284 10.25 12 10.9216 12 11.75C12 12.5784 11.3284 13.25 10.5 13.25C9.67157 13.25 9 12.5784 9 11.75ZM2 8C2 6.75736 3.00736 5.75 4.25 5.75H16.75C17.9926 5.75 19 6.75736 19 8V15.5C19 16.7426 17.9926 17.75 16.75 17.75H4.25C3.00736 17.75 2 16.7426 2 15.5V8ZM4.25 7.25C3.83579 7.25 3.5 7.58579 3.5 8V8.75H4.25C4.66421 8.75 5 8.41421 5 8V7.25H4.25ZM3.5 13.25H4.25C5.49264 13.25 6.5 14.2574 6.5 15.5V16.25H14.5V15.5C14.5 14.2574 15.5074 13.25 16.75 13.25H17.5V10.25H16.75C15.5074 10.25 14.5 9.24264 14.5 8V7.25H6.5V8C6.5 9.24264 5.49264 10.25 4.25 10.25H3.5V13.25ZM17.5 8.75V8C17.5 7.58579 17.1642 7.25 16.75 7.25H16V8C16 8.41421 16.3358 8.75 16.75 8.75H17.5ZM17.5 14.75H16.75C16.3358 14.75 16 15.0858 16 15.5V16.25H16.75C17.1642 16.25 17.5 15.9142 17.5 15.5V14.75ZM3.5 15.5C3.5 15.9142 3.83579 16.25 4.25 16.25H5V15.5C5 15.0858 4.66421 14.75 4.25 14.75H3.5V15.5ZM4.40137 19.25C4.92008 20.1467 5.8896 20.75 7.00002 20.75H17.25C19.8734 20.75 22 18.6234 22 16V10.75C22 9.6396 21.3967 8.67008 20.5 8.15137V16C20.5 17.7949 19.0449 19.25 17.25 19.25H4.40137Z" fill="white" />
                    </g>
                  </svg> */}
                  {/* <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="1">
                      <path d="M10.5 8.75C8.84315 8.75 7.5 10.0931 7.5 11.75C7.5 13.4069 8.84315 14.75 10.5 14.75C12.1569 14.75 13.5 13.4069 13.5 11.75C13.5 10.0931 12.1569 8.75 10.5 8.75ZM9 11.75C9 10.9216 9.67157 10.25 10.5 10.25C11.3284 10.25 12 10.9216 12 11.75C12 12.5784 11.3284 13.25 10.5 13.25C9.67157 13.25 9 12.5784 9 11.75ZM2 8C2 6.75736 3.00736 5.75 4.25 5.75H16.75C17.9926 5.75 19 6.75736 19 8V15.5C19 16.7426 17.9926 17.75 16.75 17.75H4.25C3.00736 17.75 2 16.7426 2 15.5V8ZM4.25 7.25C3.83579 7.25 3.5 7.58579 3.5 8V8.75H4.25C4.66421 8.75 5 8.41421 5 8V7.25H4.25ZM3.5 13.25H4.25C5.49264 13.25 6.5 14.2574 6.5 15.5V16.25H14.5V15.5C14.5 14.2574 15.5074 13.25 16.75 13.25H17.5V10.25H16.75C15.5074 10.25 14.5 9.24264 14.5 8V7.25H6.5V8C6.5 9.24264 5.49264 10.25 4.25 10.25H3.5V13.25ZM17.5 8.75V8C17.5 7.58579 17.1642 7.25 16.75 7.25H16V8C16 8.41421 16.3358 8.75 16.75 8.75H17.5ZM17.5 14.75H16.75C16.3358 14.75 16 15.0858 16 15.5V16.25H16.75C17.1642 16.25 17.5 15.9142 17.5 15.5V14.75ZM3.5 15.5C3.5 15.9142 3.83579 16.25 4.25 16.25H5V15.5C5 15.0858 4.66421 14.75 4.25 14.75H3.5V15.5ZM4.40137 19.25C4.92008 20.1467 5.8896 20.75 7.00002 20.75H17.25C19.8734 20.75 22 18.6234 22 16V10.75C22 9.6396 21.3967 8.67008 20.5 8.15137V16C20.5 17.7949 19.0449 19.25 17.25 19.25H4.40137Z" fill="#38BBF4" />
                    </g>
                  </svg> */}
                </span>
              </div>
              {show ?
                <>
                  <div className="form-floating mb-3">
                    <input type='text'
                      maxLength={11}
                      className={`form-control `}
                      placeholder="Enter End Value"
                      onChange={(e) => maxSalaryFormatter(e)}
                      value={maxValue}
                    />
                    <label htmlFor="floatingInput">{STRING_CONSTANTS.employeeProfileCompletionPages.step7.enter_end_value}</label>
                    <span className="input-group-text">
                      <img src={UtilityFunctions.getImageURL() + "money-fill-gray.svg"} alt="" />
                      <img src={UtilityFunctions.getImageURL() + "money-fill-blue.svg"} alt="" />
                      {/* <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                          <path d="M10.5 8.75C8.84315 8.75 7.5 10.0931 7.5 11.75C7.5 13.4069 8.84315 14.75 10.5 14.75C12.1569 14.75 13.5 13.4069 13.5 11.75C13.5 10.0931 12.1569 8.75 10.5 8.75ZM9 11.75C9 10.9216 9.67157 10.25 10.5 10.25C11.3284 10.25 12 10.9216 12 11.75C12 12.5784 11.3284 13.25 10.5 13.25C9.67157 13.25 9 12.5784 9 11.75ZM2 8C2 6.75736 3.00736 5.75 4.25 5.75H16.75C17.9926 5.75 19 6.75736 19 8V15.5C19 16.7426 17.9926 17.75 16.75 17.75H4.25C3.00736 17.75 2 16.7426 2 15.5V8ZM4.25 7.25C3.83579 7.25 3.5 7.58579 3.5 8V8.75H4.25C4.66421 8.75 5 8.41421 5 8V7.25H4.25ZM3.5 13.25H4.25C5.49264 13.25 6.5 14.2574 6.5 15.5V16.25H14.5V15.5C14.5 14.2574 15.5074 13.25 16.75 13.25H17.5V10.25H16.75C15.5074 10.25 14.5 9.24264 14.5 8V7.25H6.5V8C6.5 9.24264 5.49264 10.25 4.25 10.25H3.5V13.25ZM17.5 8.75V8C17.5 7.58579 17.1642 7.25 16.75 7.25H16V8C16 8.41421 16.3358 8.75 16.75 8.75H17.5ZM17.5 14.75H16.75C16.3358 14.75 16 15.0858 16 15.5V16.25H16.75C17.1642 16.25 17.5 15.9142 17.5 15.5V14.75ZM3.5 15.5C3.5 15.9142 3.83579 16.25 4.25 16.25H5V15.5C5 15.0858 4.66421 14.75 4.25 14.75H3.5V15.5ZM4.40137 19.25C4.92008 20.1467 5.8896 20.75 7.00002 20.75H17.25C19.8734 20.75 22 18.6234 22 16V10.75C22 9.6396 21.3967 8.67008 20.5 8.15137V16C20.5 17.7949 19.0449 19.25 17.25 19.25H4.40137Z" fill="white" />
                        </g>
                      </svg> */}
                      {/* <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="1">
                          <path d="M10.5 8.75C8.84315 8.75 7.5 10.0931 7.5 11.75C7.5 13.4069 8.84315 14.75 10.5 14.75C12.1569 14.75 13.5 13.4069 13.5 11.75C13.5 10.0931 12.1569 8.75 10.5 8.75ZM9 11.75C9 10.9216 9.67157 10.25 10.5 10.25C11.3284 10.25 12 10.9216 12 11.75C12 12.5784 11.3284 13.25 10.5 13.25C9.67157 13.25 9 12.5784 9 11.75ZM2 8C2 6.75736 3.00736 5.75 4.25 5.75H16.75C17.9926 5.75 19 6.75736 19 8V15.5C19 16.7426 17.9926 17.75 16.75 17.75H4.25C3.00736 17.75 2 16.7426 2 15.5V8ZM4.25 7.25C3.83579 7.25 3.5 7.58579 3.5 8V8.75H4.25C4.66421 8.75 5 8.41421 5 8V7.25H4.25ZM3.5 13.25H4.25C5.49264 13.25 6.5 14.2574 6.5 15.5V16.25H14.5V15.5C14.5 14.2574 15.5074 13.25 16.75 13.25H17.5V10.25H16.75C15.5074 10.25 14.5 9.24264 14.5 8V7.25H6.5V8C6.5 9.24264 5.49264 10.25 4.25 10.25H3.5V13.25ZM17.5 8.75V8C17.5 7.58579 17.1642 7.25 16.75 7.25H16V8C16 8.41421 16.3358 8.75 16.75 8.75H17.5ZM17.5 14.75H16.75C16.3358 14.75 16 15.0858 16 15.5V16.25H16.75C17.1642 16.25 17.5 15.9142 17.5 15.5V14.75ZM3.5 15.5C3.5 15.9142 3.83579 16.25 4.25 16.25H5V15.5C5 15.0858 4.66421 14.75 4.25 14.75H3.5V15.5ZM4.40137 19.25C4.92008 20.1467 5.8896 20.75 7.00002 20.75H17.25C19.8734 20.75 22 18.6234 22 16V10.75C22 9.6396 21.3967 8.67008 20.5 8.15137V16C20.5 17.7949 19.0449 19.25 17.25 19.25H4.40137Z" fill="#38BBF4" />
                        </g>
                      </svg> */}
                    </span>
                  </div>
                  <span className="text-white"
                    onClick={() => {
                      setShow(false)
                      setMaxValue("$")
                    }}>
                    {STRING_CONSTANTS.employeeProfileCompletionPages.step7.remove_range}
                  </span>
                </>
                :
                <span className="text-white" onClick={() => {
                  setShow(true)
                }}>
                  {STRING_CONSTANTS.employeeProfileCompletionPages.step7.add_range}
                </span>
              }
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </form>
    <div className="next-wrap">
      <button className={
        parseFloat(`${minValue}`?.replace(/[^\d\.]/g, ''))
          ?
          "cricle-next"
          : "cricle-next-inactive"
      }
        onClick={(e) => submitHandler(e)}>
        <img
          alt=""
          src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
        />
      </button>
    </div>
  </>
}

export default withRouter(Salary)