import React, { useEffect, useRef, useState } from "react"
import ValidationErrorToast from "../../../components/validationErrorToast";
import { api } from "../../../middleware/api"
import { UtilityFunctions } from "../../../utils/UtilityFunctions"
import { useDispatch, useSelector } from "react-redux";
import { actionCreators, State } from "../../../store";
import { bindActionCreators } from "redux";

const JobCategory = (props: any) => {
    const [categories, setCategories] = useState<any>([]);
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [searchInput, setSearchInput] = useState<any>();
    const [searchResults, setSearchResults] = useState<any>();
    // const inputRef = useRef<any>('')

    const state = useSelector((state: State) => state)
    const dispatch = useDispatch();
    const { setJobCategory, setJobSubcategory } = bindActionCreators(actionCreators, dispatch)

    useEffect(() => {
        api.get('category/', null)
            .then(res => {
                setCategories(res)
                setSearchResults(res)
                setSelectedCategory(state.newJob.category)
                // inputRef.current.value = state.newJob.category.name
            })
    }, [state.newJob.category])
    // console.log(state)
    useEffect(() => {
        if (searchInput) {
            //setSelectedCategory('')
            setSearchResults(categories?.filter(category => {
                return category?.name?.toLowerCase().indexOf(searchInput.toLowerCase()) > -1;
            }))
        }
        else {
           // setSelectedCategory('')
            setSearchResults([...categories])
        }
    }, [searchInput])

    const submitHandler = (e) => {
        e.preventDefault();
        if (!selectedCategory.id) {
            ValidationErrorToast('Job Category', 'Please select a job category from the results before proceeding')
        }
        else {
            setJobCategory(selectedCategory)
            props.nav.next()
        }
    }

    const renderCategoryList =
        searchResults?.
            map(category => {
                return (
                    <button key={category?.id} className={selectedCategory?.id === category?.id ? 'active' : ""} type="button" onChange={() => setJobCategory({})} onClick={e => {
                        setJobSubcategory({});
                        if (category?.id === selectedCategory?.id) {
                            setSelectedCategory('')
                            // inputRef.current.value = ''
                        }
                        else {
                            setSelectedCategory(category);
                            // inputRef.current.value = category?.name
                        }
                    }}>{category?.name}</button>)
            })



    return <>
        <div className='top-divde-spa'>
            <span>
                <i
                    className="fa fa-angle-left text-white d-flex justify-content-between"
                    aria-hidden="true"
                    onClick={() => {
                        props?.history?.push('/dashboard/home')
                    }}
                ></i>
            </span>
            <div className="step-content paragraph-spacin">
                <button type='button' disabled>
                    Create a Job
                </button>
                <p>Select a job category that applies to your new job</p>
                <div className="step-text">
                    Step 1/8
                </div>
            </div>
        </div>
        <form onSubmit={submitHandler}>
            <div className="form-wrap">
                <div className="v-form-width min-height-auto">
                    <div className="form-floating mb-3 label-dark">
                        <input type='text' className="form-control" placeholder="Search Industry" value={searchInput} onChange={e => setSearchInput(e.target.value)}></input>
                        <label htmlFor="floatingInput">Search Industry</label>
                        <span className="input-group-text">
                            <img src={UtilityFunctions.getImageURL() + "search-with-shadow.svg"} alt="" />
                            <img src={UtilityFunctions.getImageURL() + "search-with-shadow-fill.svg"} alt="" />
                            {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_f_4_8)">
                                    <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                                    <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                                </g>
                                <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                                <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                                <defs>
                                    <filter id="filter0_f_4_8" x="-3" y="-3" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_4_8" />
                                    </filter>
                                </defs>
                            </svg> */}
                            {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_f_4_8)">
                                    <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                                    <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                                </g>
                                <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                                <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                                <defs>
                                    <filter id="filter0_f_4_8" x="-3" y="-3" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_4_8" />
                                    </filter>
                                </defs>
                            </svg> */}
                        </span>
                    </div>
                </div>
                <div className="all-cat-width text-white">
                    {(!renderCategoryList?.length && !searchInput?.length) ? <div></div> : renderCategoryList?.length > 0 ? renderCategoryList : "No matching category found. Please try again !"}
                </div>
            </div>
        </form>
        <div className="next-wrap">
            <button className={selectedCategory?.name ? "cricle-next" : "cricle-next-inactive"}
                onClick={(e) => submitHandler(e)}>
                <img
                    alt=""
                    src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
                />
            </button>
        </div>
    </>
}

export default (JobCategory)