import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Logout from "../../components/logout";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import ImageCrop from "../../common/ui/ImageCrop";
import { DatePicker } from "@blueprintjs/datetime";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import { api } from "../../middleware/api";
import ValidationErrorToast from "../../components/validationErrorToast";
import { cookieService, userService } from "../../_services";
import SuccessToast from "../../components/successToast";

interface subcategoryObject {
  category: "",
  id: "",
  name: "",
}

const SubCategory = (props) => {
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const isProfileBeingUpdated = props?.location?.state?.updateProfile || false;

  useEffect(() => {
    props?.userData?.category?.[0] &&
      api.get(`sub-category/?categoryIds=${props?.userData?.category?.[0]}`, null)
        .then((res): any => {
          setSearchResults(res)
          setSubcategories(res)
        })
  }, [props?.userData?.category?.[0]])

  useEffect(() => {
    if (props?.userData?.subCategory?.length && subcategories) {
      let tempArr = [];
      for (let selectedSub of props?.userData?.subCategory) {
        for (let sub of subcategories) {
          if (sub?.id === selectedSub) {
            tempArr?.push(sub)
          }
        }
      }
      setSelectedSubcategories(tempArr)
    }
  }, [props?.userData, subcategories])



  const subIDArray = selectedSubcategories?.map(sub => sub?.id)

  //HANDLES SUBCAT SELECTION, REMOVES A SUBCAT IF ITS ALREADY SELECTED, OTHERWISE PUSHES NEW SUBCAT
  const subHandler = (subcategory: subcategoryObject) => {
    if (subIDArray?.includes(subcategory?.id)) {
      const tempArr = selectedSubcategories
      tempArr?.splice(tempArr.indexOf(tempArr.find(item => item.id === subcategory.id)), 1)
      setSelectedSubcategories([...tempArr])
    }
    else {
      setSelectedSubcategories(prev => [...prev, subcategory])
    }
  }
  useEffect(() => {
    if (searchInput) {
      // setSelectedCategory([])
      setSearchResults(subcategories?.filter(category => {
        return category?.name?.toLowerCase().indexOf(searchInput.toLowerCase()) > -1;
      }))
    }
    else {
      // setSelectedCategory('')
      setSearchResults([...subcategories])
    }
  }, [searchInput])

  const renderSubcategoryList =
    searchResults?.
      map(subcategory => {
        return <button key={subcategory?.id}
          className={subIDArray?.includes(subcategory?.id) ? 'active' : ""}
          type="button"
          onClick={(e) => subHandler(subcategory)}>
          {subcategory?.name}
        </button>
      })
  const submitHandler = (e) => {
    e.preventDefault();
    if (!selectedSubcategories.length) {
      ValidationErrorToast("Job Subcategory", "Please select atleast 1 subcategory before proceeding")
    }
    else {
      userService.userProfile({ subCategory: subIDArray })
        .then(res =>
          cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res.data)
        )
        .then(() => {
          if (isProfileBeingUpdated) {
            props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "updateProfile" })
            SuccessToast("Success!", "Your profile details have been updated successfully")
          }
          else props.nav.next()
        })
        .catch((e) => ValidationErrorToast(e[0], e[1]))
    };
  }

  return (
    <>
      <div className='top-divde-spa'>
        <span>
          <i
            className="fa fa-angle-left text-white d-flex justify-content-between"
            aria-hidden="true"
            onClick={() => {
              props?.nav?.back()
            }}
          ></i>
        </span>
        <div className="step-content paragraph-spacin">
          <button type='button' disabled>
            {STRING_CONSTANTS.employeeProfileCompletionPages.step4.title}
          </button>
          <p>
            {STRING_CONSTANTS.employeeProfileCompletionPages.step4.subtitle}
          </p>
          <div className="step-text">
            {isProfileBeingUpdated ? "" : STRING_CONSTANTS.employeeProfileCompletionPages.step4.step}
          </div>
        </div>
      </div>
      <form onSubmit={submitHandler}>
        <div className="form-wrap mh-auto">
          <div className="v-form-width min-height-auto">
            <div className="form-floating mb-3 label-dark">
              <input type='text'
                className="form-control"
                placeholder={STRING_CONSTANTS.employeeProfileCompletionPages.step4.search_job_subcategory}
                onChange={e => setSearchInput(e.target.value)}></input>
              <label htmlFor="floatingInput">{STRING_CONSTANTS.employeeProfileCompletionPages.step4.search_job_subcategory}</label>
              <span className="input-group-text">
                <img src={UtilityFunctions.getImageURL() + "search-with-shadow.svg"} alt="" />
                <img src={UtilityFunctions.getImageURL() + "search-with-shadow-fill.svg"} alt="" />
                {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_f_4_8)">
                    <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                    <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                  </g>
                  <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                  <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                  <defs>
                    <filter id="filter0_f_4_8" x="-3" y="-3" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                      <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_4_8" />
                    </filter>
                  </defs>
                </svg> */}
                {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_f_4_8)">
                    <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                    <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                  </g>
                  <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                  <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                  <defs>
                    <filter id="filter0_f_4_8" x="-3" y="-3" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                      <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_4_8" />
                    </filter>
                  </defs>
                </svg> */}
              </span>
            </div>
          </div>
          <div className="all-cat-width text-white">
            {(!renderSubcategoryList?.length && !searchInput?.length) ? <div></div> : renderSubcategoryList?.length > 0 ? renderSubcategoryList : "No matching category found. Please try again !"}
          </div>
        </div>
      </form >
      <div className="next-wrap">
        <button className={
          selectedSubcategories.length ?
            "cricle-next"
            : "cricle-next-inactive"
        }
          onClick={(e) => submitHandler(e)}>
          <img
            alt=""
            src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
          />
        </button>
      </div>
    </>
  )
}

export default withRouter(SubCategory)