import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators, State } from "../../../store";
import { bindActionCreators } from "redux";
import { dataURLtoFile, UtilityFunctions } from "../../../utils/UtilityFunctions"
import { api } from "../../../middleware/api";
import { forEach } from 'lodash';

import ValidationErrorToast from "../../../components/validationErrorToast";
import { setEmployeesList } from "../../../store/action-creators";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import moment from "moment";

const JobSalary = (props: any) => {
    const dispatch = useDispatch();
    const state = useSelector((state: State) => state);
    const { setJobMaxSalary, setJobMinSalary, setJobType, setJobRateType, setResetJob } = bindActionCreators(actionCreators, dispatch);

    const [minValue, setMinValue] = useState<any>("$");
    const [maxValue, setMaxValue] = useState<any>("$");
    const minSalaryRef = useRef<any>(null);
    const maxSalaryRef = useRef<any>(null);
    const [show, setShow] = useState(false);//Max Salary Input render conditionally

    const [type, setType] = useState("");
    const [checkout, setCheckout] = useState("")

    const [newJobApiData, setNewJobApiData] = useState<any>();
    const [editJobApiData, setEditJobApiData] = useState<any>();

    const dateFormatter = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result.toISOString().substring(0, 10);
    }

    const minSalaryFormatter = (e) => {
        setMinValue("$" + `${e?.target?.value?.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`)
        setJobMinSalary("$" + `${e?.target?.value?.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`)
    }

    const maxSalaryFormatter = (e) => {
        setMaxValue("$" + `${e?.target?.value?.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`)
        setJobMaxSalary("$" + `${e?.target?.value?.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`)
    }

    useEffect(() => {
        const minSal = state?.newJob?.minSalary;
        const maxSal = state?.newJob?.maxSalary;
        // console.log(minSal, maxSal)
        // console.log("USEEFFECT CALLED")
        if (minSal > 0) {
            setMinValue("$" + Math.round(minSal).toLocaleString())
        }
        if (maxSal > 0) {
            setMaxValue("$" + Math.round(maxSal).toLocaleString())
            setShow(true)
        }
    }, [state.newJob])

    // console.log(minValue, maxValue)

    useEffect(() => {
        if (!props?.editJob?.editJob) {
            setNewJobApiData({
                category: state?.newJob?.category.id,
                cover: state?.newJob?.cover,
                description: state?.newJob?.description,
                experience: state?.newJob?.experience,
                location: state?.newJob?.location,
                maxSalary: parseFloat(`${maxValue}`?.replace(/[^\d\.]/g, '')) ? parseFloat(`${maxValue}`?.replace(/[^\d\.]/g, '')) : 0,
                minSalary: parseFloat(`${minValue}`?.replace(/[^\d\.]/g, '')) ? parseFloat(`${minValue}`?.replace(/[^\d\.]/g, '')) : 0,
                salaryCheckoutType: state?.newJob?.salaryCheckoutType,
                startFrom: state?.newJob?.startFrom ? dateFormatter(state.newJob.startFrom, 1) : null,
                subCategory: state?.newJob?.subCategory?.id,
                title: state?.newJob?.title,
                type: state?.newJob?.type
            })
        }
    }, [state?.newJob, minValue, maxValue])

    useEffect(() => {
        if (props?.editJob?.editJob) {
            setEditJobApiData({
                category: state?.newJob?.category.id,
                cover: state?.newJob?.cover,
                description: state?.newJob?.description,
                experience: state?.newJob?.experience,
                location: state?.newJob?.location,
                maxSalary: parseFloat(`${maxValue}`?.replace(/[^\d\.]/g, '')) ? parseFloat(`${maxValue}`?.replace(/[^\d\.]/g, '')) : 0,
                minSalary: parseFloat(`${minValue}`?.replace(/[^\d\.]/g, '')) ? parseFloat(`${minValue}`?.replace(/[^\d\.]/g, '')) : 0,
                salaryCheckoutType: state?.newJob?.salaryCheckoutType,
                startFrom: state?.newJob?.startFrom ? state?.newJob?.startFrom : null,
                subCategory: state?.newJob?.subCategory?.id,
                title: state?.newJob?.title,
                type: state?.newJob?.type
            })
        }
    }, [maxValue, minValue, checkout, type])

    const submitData = () => {
        // NEW JOB CASE 
        if (!props?.editJob?.editJob) {
            // console.log("Data Sent", newJobApiData);
            let formData = new FormData();
            forEach(newJobApiData, (value, key) => {
                if (key == "cover" && value === null) {
                    delete newJobApiData["cover"]
                }
                else if (key == 'startFrom' && value === null) {
                    delete newJobApiData["startFrom"]
                }
                else if (key == 'cover' && value && !UtilityFunctions.isValidURL(value)) {
                    formData.append(key, dataURLtoFile(value, Date.now() + '.jpeg'));
                }
                else if (key == 'location') {
                    formData.append(key, JSON.stringify(value));
                }

                else formData.append(key, value);
            });
            api.post(`job/create/`, formData)
                .then(res => {
                    props.nav.next();
                    setResetJob(null);
                })
                .catch(e => {
                    if (e[1][0] !== undefined && e[1][0]) {
                        ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_flag_error, e[1][0])
                    } else {
                        ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_flag_error, STRING_CONSTANTS.SOMETHING_WENT_WRONG)

                    }
                }

                )
        }
        // EDIT JOB CASE
        else if (props?.editJob?.editJob) {
            let formData = new FormData();
            forEach(editJobApiData, (value, key) => {
                // Existing job image is not changed                    
                if ((key == "cover" && UtilityFunctions.isValidURL(value))) {
                    delete editJobApiData.cover
                }
                if (key == "cover" || key == "startFrom" || key == "location") {
                    // Existing job image is removed
                    if (key == "cover" && value == null) {
                        formData.append(key, "")
                    }
                    // Old image is removed and new image is uploaded
                    else if (key == "cover" && !UtilityFunctions.isValidURL(value))
                        formData.append(key, dataURLtoFile(value, Date.now() + '.jpeg'))
                    // Start date is removed
                    if (key == 'startFrom' && value === null) {
                        formData.append(key, "")
                    }
                    // Start date is present
                    else if (key == 'startFrom' && value !== null) {
                        formData.append(key, moment(value).format())
                    }
                    //stringify location
                    if (key == 'location') {
                        formData.append(key, JSON.stringify(value));
                    }
                }
                else formData.append(key, value);
            });
            api.patch(`${props?.editJob?.job?.id}/job/`, formData)
                .then(() => {
                    props.nav.next();
                    setResetJob(null);
                }
                )
                .catch(e =>
                    ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_flag_error, STRING_CONSTANTS.SOMETHING_WENT_WRONG)
                    // ValidationErrorToast(e[0], e[1])
                )
        }
    }
    const submitHandler = (e) => {
        e.preventDefault();
        if (!state?.newJob?.type) {
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_job_type, STRING_CONSTANTS.validationString.req_job_type)
        } else if (!state?.newJob?.salaryCheckoutType) {
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_salary_type, STRING_CONSTANTS.validationString.req_checkout_type)
        } else if (!parseFloat(`${minValue}`?.replace(/[^\d\.]/g, ''))) {
            // console.log(parseFloat(`${minValue}`?.replace(/[^\d\.]/g, '')), minValue)
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_salary_range, STRING_CONSTANTS.validationString.req_salary_empty)
        }
        else if (show && parseFloat(`${minValue}`?.replace(/[^\d\.]/g, '')) >= parseFloat(`${maxValue}`?.replace(/[^\d\.]/g, ''))) {
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_salary_range, STRING_CONSTANTS.validationString.req_salary_valid)
        }
        else {
            setEmployeesList([]);
            submitData();
        }
    }

    return <>
        <div className='top-divde-spa'>
            <span>
                <i
                    className="fa fa-angle-left text-white d-flex justify-content-between"
                    aria-hidden="true"
                    onClick={() => {
                        props?.nav?.back()
                    }}
                ></i>
            </span>
            <div className="step-content paragraph-spacin">
                <button type='button' disabled>
                    Job Salary
                </button>
                <p>What rate is your<br /> job offering?</p>
                <div className="step-text">
                    Step 7/8
                </div>
            </div>
        </div>
        <form onSubmit={submitHandler}>
            <div className="form-wrap jobsalary-spactop">
                <div className="v-form-width">
                    <div className="form-floating1 mb-3">
                        <div className="v-top-timeing-part">
                            <button type='button' className={state?.newJob?.type === "fullTime" ? "active" : type === "fullTime" ? "active" : ""}
                                onClick={() => {
                                    setType('fullTime')
                                    setJobType('fullTime')
                                }}>Full Time</button>
                            <button type='button' className={state?.newJob?.type === "partTime" ? "active" : type === "partTime" ? "active" : ""}
                                onClick={() => {
                                    setType('partTime')
                                    setJobType('partTime')
                                }}>Part Time</button>
                            <button type='button' className={state?.newJob?.type === "casual" ? "active" : type === "casual" ? "active" : ""}
                                onClick={() => {
                                    setType('casual')
                                    setJobType('casual')
                                }}>Casual</button>
                        </div>
                        <div className="v-working-set">
                            <button type='button' className={state?.newJob?.salaryCheckoutType === "hourly" ? "active" : checkout === "hourly" ? "active" : ""}
                                onClick={() => {
                                    setJobRateType("hourly")
                                    setCheckout("hourly")
                                }}>Hourly</button>
                            <button type='button' className={state?.newJob?.salaryCheckoutType === "daily" ? "active" : checkout === "daily" ? "active" : ""}
                                onClick={() => {
                                    setJobRateType("daily")
                                    setCheckout("daily")
                                }}>Daily</button>
                            <button type='button' className={state?.newJob?.salaryCheckoutType === "weekly" ? "active" : checkout === "weekly" ? "active" : ""}
                                onClick={() => {
                                    setJobRateType("weekly")
                                    setCheckout("weekly")
                                }}>Weekly</button>
                            <button type='button' className={state?.newJob?.salaryCheckoutType === "monthly" ? "active" : checkout === "monthly" ? "active" : ""}
                                onClick={() => {
                                    setJobRateType("monthly")
                                    setCheckout("monthly")
                                }}>Monthly</button>
                            <button type='button' className={state?.newJob?.salaryCheckoutType === "yearly" ? "active" : checkout === "yearly" ? "active" : ""}
                                onClick={() => {
                                    setJobRateType("yearly")
                                    setCheckout("yearly")
                                }}>Yearly</button>
                        </div>
                        <div className="jobsalary-girdset mt-2">
                            <div className="form-floating mb-3">
                                <input type='text'
                                    maxLength={11}
                                    className={`form-control `}
                                    placeholder="Enter Start Value"
                                    ref={minSalaryRef}
                                    onChange={(e) => minSalaryFormatter(e)}
                                    value={minValue}
                                />
                                <label htmlFor="floatingInput">Enter Start Value</label>

                                <span className="input-group-text">
                                    <img src={UtilityFunctions.getImageURL() + "money-fill-gray.svg"} alt="" />
                                    <img src={UtilityFunctions.getImageURL() + "money-fill-blue.svg"} alt="" />
                                    {/* <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.5">
                                            <path d="M10.5 8.75C8.84315 8.75 7.5 10.0931 7.5 11.75C7.5 13.4069 8.84315 14.75 10.5 14.75C12.1569 14.75 13.5 13.4069 13.5 11.75C13.5 10.0931 12.1569 8.75 10.5 8.75ZM9 11.75C9 10.9216 9.67157 10.25 10.5 10.25C11.3284 10.25 12 10.9216 12 11.75C12 12.5784 11.3284 13.25 10.5 13.25C9.67157 13.25 9 12.5784 9 11.75ZM2 8C2 6.75736 3.00736 5.75 4.25 5.75H16.75C17.9926 5.75 19 6.75736 19 8V15.5C19 16.7426 17.9926 17.75 16.75 17.75H4.25C3.00736 17.75 2 16.7426 2 15.5V8ZM4.25 7.25C3.83579 7.25 3.5 7.58579 3.5 8V8.75H4.25C4.66421 8.75 5 8.41421 5 8V7.25H4.25ZM3.5 13.25H4.25C5.49264 13.25 6.5 14.2574 6.5 15.5V16.25H14.5V15.5C14.5 14.2574 15.5074 13.25 16.75 13.25H17.5V10.25H16.75C15.5074 10.25 14.5 9.24264 14.5 8V7.25H6.5V8C6.5 9.24264 5.49264 10.25 4.25 10.25H3.5V13.25ZM17.5 8.75V8C17.5 7.58579 17.1642 7.25 16.75 7.25H16V8C16 8.41421 16.3358 8.75 16.75 8.75H17.5ZM17.5 14.75H16.75C16.3358 14.75 16 15.0858 16 15.5V16.25H16.75C17.1642 16.25 17.5 15.9142 17.5 15.5V14.75ZM3.5 15.5C3.5 15.9142 3.83579 16.25 4.25 16.25H5V15.5C5 15.0858 4.66421 14.75 4.25 14.75H3.5V15.5ZM4.40137 19.25C4.92008 20.1467 5.8896 20.75 7.00002 20.75H17.25C19.8734 20.75 22 18.6234 22 16V10.75C22 9.6396 21.3967 8.67008 20.5 8.15137V16C20.5 17.7949 19.0449 19.25 17.25 19.25H4.40137Z" fill="white" />
                                        </g>
                                    </svg> */}
                                    {/* <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="1">
                                            <path d="M10.5 8.75C8.84315 8.75 7.5 10.0931 7.5 11.75C7.5 13.4069 8.84315 14.75 10.5 14.75C12.1569 14.75 13.5 13.4069 13.5 11.75C13.5 10.0931 12.1569 8.75 10.5 8.75ZM9 11.75C9 10.9216 9.67157 10.25 10.5 10.25C11.3284 10.25 12 10.9216 12 11.75C12 12.5784 11.3284 13.25 10.5 13.25C9.67157 13.25 9 12.5784 9 11.75ZM2 8C2 6.75736 3.00736 5.75 4.25 5.75H16.75C17.9926 5.75 19 6.75736 19 8V15.5C19 16.7426 17.9926 17.75 16.75 17.75H4.25C3.00736 17.75 2 16.7426 2 15.5V8ZM4.25 7.25C3.83579 7.25 3.5 7.58579 3.5 8V8.75H4.25C4.66421 8.75 5 8.41421 5 8V7.25H4.25ZM3.5 13.25H4.25C5.49264 13.25 6.5 14.2574 6.5 15.5V16.25H14.5V15.5C14.5 14.2574 15.5074 13.25 16.75 13.25H17.5V10.25H16.75C15.5074 10.25 14.5 9.24264 14.5 8V7.25H6.5V8C6.5 9.24264 5.49264 10.25 4.25 10.25H3.5V13.25ZM17.5 8.75V8C17.5 7.58579 17.1642 7.25 16.75 7.25H16V8C16 8.41421 16.3358 8.75 16.75 8.75H17.5ZM17.5 14.75H16.75C16.3358 14.75 16 15.0858 16 15.5V16.25H16.75C17.1642 16.25 17.5 15.9142 17.5 15.5V14.75ZM3.5 15.5C3.5 15.9142 3.83579 16.25 4.25 16.25H5V15.5C5 15.0858 4.66421 14.75 4.25 14.75H3.5V15.5ZM4.40137 19.25C4.92008 20.1467 5.8896 20.75 7.00002 20.75H17.25C19.8734 20.75 22 18.6234 22 16V10.75C22 9.6396 21.3967 8.67008 20.5 8.15137V16C20.5 17.7949 19.0449 19.25 17.25 19.25H4.40137Z" fill="#38BBF4" />
                                        </g>
                                    </svg> */}
                                </span>
                            </div>
                            {show ?
                                <>
                                    <div className="form-floating mb-3 ">
                                        <input type='text'
                                            maxLength={11}
                                            className={`form-control `}
                                            placeholder="Enter End Value"
                                            ref={maxSalaryRef}
                                            onChange={(e) => maxSalaryFormatter(e)}
                                            value={maxValue}
                                        />
                                        <label htmlFor="floatingInput">Enter End Value</label>
                                        <span className="input-group-text">
                                            <img src={UtilityFunctions.getImageURL() + "money-fill-gray.svg"} alt="" />
                                            <img src={UtilityFunctions.getImageURL() + "money-fill-blue.svg"} alt="" />
                                            {/* <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.5">
                                                    <path d="M10.5 8.75C8.84315 8.75 7.5 10.0931 7.5 11.75C7.5 13.4069 8.84315 14.75 10.5 14.75C12.1569 14.75 13.5 13.4069 13.5 11.75C13.5 10.0931 12.1569 8.75 10.5 8.75ZM9 11.75C9 10.9216 9.67157 10.25 10.5 10.25C11.3284 10.25 12 10.9216 12 11.75C12 12.5784 11.3284 13.25 10.5 13.25C9.67157 13.25 9 12.5784 9 11.75ZM2 8C2 6.75736 3.00736 5.75 4.25 5.75H16.75C17.9926 5.75 19 6.75736 19 8V15.5C19 16.7426 17.9926 17.75 16.75 17.75H4.25C3.00736 17.75 2 16.7426 2 15.5V8ZM4.25 7.25C3.83579 7.25 3.5 7.58579 3.5 8V8.75H4.25C4.66421 8.75 5 8.41421 5 8V7.25H4.25ZM3.5 13.25H4.25C5.49264 13.25 6.5 14.2574 6.5 15.5V16.25H14.5V15.5C14.5 14.2574 15.5074 13.25 16.75 13.25H17.5V10.25H16.75C15.5074 10.25 14.5 9.24264 14.5 8V7.25H6.5V8C6.5 9.24264 5.49264 10.25 4.25 10.25H3.5V13.25ZM17.5 8.75V8C17.5 7.58579 17.1642 7.25 16.75 7.25H16V8C16 8.41421 16.3358 8.75 16.75 8.75H17.5ZM17.5 14.75H16.75C16.3358 14.75 16 15.0858 16 15.5V16.25H16.75C17.1642 16.25 17.5 15.9142 17.5 15.5V14.75ZM3.5 15.5C3.5 15.9142 3.83579 16.25 4.25 16.25H5V15.5C5 15.0858 4.66421 14.75 4.25 14.75H3.5V15.5ZM4.40137 19.25C4.92008 20.1467 5.8896 20.75 7.00002 20.75H17.25C19.8734 20.75 22 18.6234 22 16V10.75C22 9.6396 21.3967 8.67008 20.5 8.15137V16C20.5 17.7949 19.0449 19.25 17.25 19.25H4.40137Z" fill="white" />
                                                </g>
                                            </svg> */}
                                            {/* <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="1">
                                                    <path d="M10.5 8.75C8.84315 8.75 7.5 10.0931 7.5 11.75C7.5 13.4069 8.84315 14.75 10.5 14.75C12.1569 14.75 13.5 13.4069 13.5 11.75C13.5 10.0931 12.1569 8.75 10.5 8.75ZM9 11.75C9 10.9216 9.67157 10.25 10.5 10.25C11.3284 10.25 12 10.9216 12 11.75C12 12.5784 11.3284 13.25 10.5 13.25C9.67157 13.25 9 12.5784 9 11.75ZM2 8C2 6.75736 3.00736 5.75 4.25 5.75H16.75C17.9926 5.75 19 6.75736 19 8V15.5C19 16.7426 17.9926 17.75 16.75 17.75H4.25C3.00736 17.75 2 16.7426 2 15.5V8ZM4.25 7.25C3.83579 7.25 3.5 7.58579 3.5 8V8.75H4.25C4.66421 8.75 5 8.41421 5 8V7.25H4.25ZM3.5 13.25H4.25C5.49264 13.25 6.5 14.2574 6.5 15.5V16.25H14.5V15.5C14.5 14.2574 15.5074 13.25 16.75 13.25H17.5V10.25H16.75C15.5074 10.25 14.5 9.24264 14.5 8V7.25H6.5V8C6.5 9.24264 5.49264 10.25 4.25 10.25H3.5V13.25ZM17.5 8.75V8C17.5 7.58579 17.1642 7.25 16.75 7.25H16V8C16 8.41421 16.3358 8.75 16.75 8.75H17.5ZM17.5 14.75H16.75C16.3358 14.75 16 15.0858 16 15.5V16.25H16.75C17.1642 16.25 17.5 15.9142 17.5 15.5V14.75ZM3.5 15.5C3.5 15.9142 3.83579 16.25 4.25 16.25H5V15.5C5 15.0858 4.66421 14.75 4.25 14.75H3.5V15.5ZM4.40137 19.25C4.92008 20.1467 5.8896 20.75 7.00002 20.75H17.25C19.8734 20.75 22 18.6234 22 16V10.75C22 9.6396 21.3967 8.67008 20.5 8.15137V16C20.5 17.7949 19.0449 19.25 17.25 19.25H4.40137Z" fill="#38BBF4" />
                                                </g>
                                            </svg> */}
                                        </span>
                                    </div>
                                    <span className="text-white"
                                        onClick={() => {
                                            setNewJobApiData(prev => { return { ...prev, maxSalary: 0 } })
                                            setEditJobApiData(prev => { return { ...prev, maxSalary: 0 } })
                                            setShow(false)
                                            setMaxValue("$")
                                        }}>
                                        - Remove Range
                                    </span>
                                </>
                                :
                                <span className="text-white" onClick={() => {
                                    setNewJobApiData(prev => { return { ...prev, maxSalary: parseFloat(`${maxValue}`?.replace(/[^\d\.]/g, '')) } })
                                    setEditJobApiData(prev => { return { ...prev, maxSalary: parseFloat(`${maxValue}`?.replace(/[^\d\.]/g, '')) } })
                                    setShow(true)
                                }}>+ Add Range</span>
                            }
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </form>
        <div className="next-wrap">
            <button className={state?.newJob?.type && state?.newJob?.salaryCheckoutType && parseFloat(`${minValue}`?.replace(/[^\d\.]/g, '')) ? "cricle-next" : "cricle-next-inactive"}
                onClick={(e) => submitHandler(e)}>
                <img
                    alt=""
                    src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
                />
            </button>
        </div>
    </>
}

export default (JobSalary)