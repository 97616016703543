import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";

//this method allow to get heading text as per selected step
export const getHeadingText = (step: number) => {
  let headingText = "";
  switch (
    Number(step) //here parsing into number because if the user passing string in that case it also works
  ) {
    case 1:
      headingText = STRING_CONSTANTS.create_job.cat_heading;
      break;

    case 2:
      headingText = STRING_CONSTANTS.create_job.sub_cat_heading;
      break;

    case 3:
      headingText = STRING_CONSTANTS.create_job.image_heading;
      break;

    case 4:
      headingText = STRING_CONSTANTS.create_job.job_desc_heading;
      break;

    case 5:
      headingText = STRING_CONSTANTS.create_job.location_heading;
      break;

    case 6:
      headingText = STRING_CONSTANTS.create_job.job_exp_heading;
      break;

    case 7:
      headingText = STRING_CONSTANTS.create_job.job_salary_heading;
      break;

    case 8:
      headingText = STRING_CONSTANTS.create_job.job_comp_heading;
      break;

    default:
      headingText = "";
  }
  return headingText;
};

//this method allow to get button text as per selected step
export const getBtnText = (step: number) => {
  let btnText = "";
  switch (
    Number(step) //here parsing into number because if the user passing string in that case it also works
  ) {
    case 1:
    case 2:
      btnText = STRING_CONSTANTS.create_job.create_a_job;
      break;

    case 3:
      btnText = STRING_CONSTANTS.create_job.image_btn;
      break;

    case 4:
    case 5:
    case 6:
      btnText = STRING_CONSTANTS.create_job.job_desc_btn;
      break;

    case 7:
      btnText = STRING_CONSTANTS.create_job.job_salary_btn;
      break;

    case 8:
      btnText = STRING_CONSTANTS.create_job.job_comp_btn;
      break;
    default:
      btnText = "";
  }
  return btnText;
};

export const createJobFormData = () => ({
  category: null,
  sub_category: null,
  image: "",
  job_description: {
    description: "",
    date: "",
  },
  location: {
    lat: "",
    lng: "",
    address: "",
  },
  experience: "",
  salary: {
    amount: "",
    type: "",
  },
});

export const defaultImgSize = 15;
export const imgAcceptType = [
  "jpg",
  "jpeg",
  "png",
  "JPG",
  "JPEG",
  "PNG",
  "gif",
  "GIF",
  'heic'
];
