import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import Logout from "../../components/logout";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import { isEmpty, includes } from 'lodash';
import locatingFns from "../../common/GoogleLocating";
import ValidationErrorToast from "../../components/validationErrorToast";
import SlideRule from 'react-slide-rule';
import { cookieService, userService } from "../../_services";
import SuccessToast from "../../components/successToast";

const Location = (props) => {

  //For Location
  const searchRef = useRef<any>(null);
  const [searchTerm, setSearchTerm] = useState<any>();
  const [primaryAddress, setPrimaryAddress] = useState<any>();
  const [btnClick, setBtnClick] = useState(false);
  const isProfileBeingUpdated = props?.location?.state?.updateProfile || false;
  //For Slider
  const [value, setValue] = useState<any>(0);

  useEffect(() => {
    initMapScript().then(() => initAutocomplete())
  }, []);

  useEffect(() => {
    if (props?.userData?.location) {
      setPrimaryAddress(props?.userData?.location)
      setSearchTerm(props?.userData?.location?.address)
      searchRef.current.value = props?.userData?.location?.address
      setValue(props?.userData?.location?.radius)
    }
  }, [props?.userData?.location])
  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${locatingFns.mapApiJs}?key=${locatingFns.apiKey}&libraries=places&v=weekly`;
    return locatingFns.loadAsyncScript(src);
  }

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    const lat = place?.geometry?.location?.lat();
    const lng = place?.geometry?.location?.lng();
    setPrimaryAddress(prev => { return { ...prev, address: searchRef.current.value, latitude: lat, longitude: lng, radius: 0 } });
  }

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchRef.current) return;
    const autocomplete = new window.google.maps.places.Autocomplete(searchRef.current, locatingFns.restrictionObj);
    window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
      let location = (locatingFns.getDataFromParseGoogleAddressComponents(autocomplete.getPlace()))
      setPrimaryAddress(prev => { return { ...prev, ...location } })
    })
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));
  }

  // A function that searches on google using given latitude and longitude
  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    document.body.classList.add('loading-indicator');
    const url = `${locatingFns.geocodeJson}?key=${locatingFns.apiKey}&latlng=${lat},${lng}`;
    searchRef.current.value = "Getting your location...";
    fetch(url)
      .then(response => response.json())
      .then(location => {
        const place = location.results[0];
        const _address = locatingFns.extractAddress(place);
        searchRef.current.value = _address.plain();
        setPrimaryAddress({
          radius: 0,
          latitude: lat,
          longitude: lng,
          address: searchRef.current.value,
          ...locatingFns.getDataFromParseGoogleAddressComponents(location.results[0])
        })
        setSearchTerm(_address.plain())
        document.body.classList.remove('loading-indicator');
      })
  }
  // A function that finds latitude and longitude of the system and then executes the above reverseGeocode function
  function findMyLocation() {
    document.body.classList.add('loading-indicator');
    setBtnClick(false)
    return new Promise((resolve, reject) => {
      navigator.permissions ?

        // Permission API is implemented
        navigator.permissions.query({
          name: 'geolocation'
        }).then(permission =>
          // is geolocation granted?
          includes(["granted", "prompt"], permission.state)
            ? navigator.geolocation.getCurrentPosition(position => reverseGeocode(position.coords)) :
            (document.body.classList.remove('loading-indicator'), ValidationErrorToast(STRING_CONSTANTS.common.access_denied, STRING_CONSTANTS.common.permission_denied))
        )
        :
        // Permission API was not implemented
        reject(new Error("Permission API is not supported") &&
          ValidationErrorToast(STRING_CONSTANTS.common.access_denied, STRING_CONSTANTS.common.permission_denied) &&
          document.body.classList.remove('loading-indicator')
        )
    }
    )
  }

  const submitHandler = (e) => {
    setBtnClick(true)
    e.preventDefault();
    if (!primaryAddress || (primaryAddress?.address !== searchRef.current.value)) {
      ValidationErrorToast("Job Location", "Please enter a valid location before proceeding")
    }
    else {
      const params = {
        location: { ...primaryAddress, radius: value }
      }
      userService.userProfile(params)
        .then(res =>
          cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res.data)
        )
        .then(() => {
          if (isProfileBeingUpdated) {
            props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "updateProfile" })
            SuccessToast("Success!", "Your profile details have been updated successfully")
          }
          else props.nav.next()
        })
        .catch((e) => ValidationErrorToast(e[0], e[1]))
    };
  }

  return (
    <>
      <div className='top-divde-spa'>
        <span>
          <i
            className="fa fa-angle-left text-white d-flex justify-content-between"
            aria-hidden="true"
            onClick={() => {
              isProfileBeingUpdated ?
                props.history.push("/dashboard/settings/", { tab: "updateProfile" }) :
                props?.nav?.back()
            }}
          ></i>
        </span>
        <div className="step-content signup-mar">
          <button type='button' disabled>
            {STRING_CONSTANTS.employeeProfileCompletionPages.step5.title}
          </button>
          <p>
            {STRING_CONSTANTS.employeeProfileCompletionPages.step5.subtitle}
          </p>
          <div className="step-text">
            {STRING_CONSTANTS.employeeProfileCompletionPages.step5.step}
          </div>
        </div>
      </div>
      <form onSubmit={submitHandler}>
        <div className="form-wrap mb-0">
          <div className="v-form-width">
            <div className="busins-location">
              <div className="form-floating mb-3">
                <input
                  ref={searchRef}
                  onChange={(e) => {
                    setSearchTerm(e.target.value)
                  }}
                  type="text"
                  className={`form-control ${btnClick && isEmpty(searchTerm) ? 'error' : ""}`}
                  placeholder={STRING_CONSTANTS.employeeProfileCompletionPages.step5.enter_address} />
                <label htmlFor="floatingInput">
                  {STRING_CONSTANTS.employeeProfileCompletionPages.step5.enter_address}
                </label>
                <button className="current-location-btn" type='button'
                  onClick={findMyLocation}>
                  {STRING_CONSTANTS.employeeProfileCompletionPages.step5.use_current_location}
                  {/* <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 9.5C8.38071 9.5 9.5 8.38071 9.5 7C9.5 5.61929 8.38071 4.5 7 4.5C5.61929 4.5 4.5 5.61929 4.5 7C4.5 8.38071 5.61929 9.5 7 9.5ZM7.5 0.5C7.5 0.223858 7.27614 0 7 0C6.72386 0 6.5 0.223858 6.5 0.5V2.02487C4.13785 2.2594 2.25932 4.13788 2.02471 6.5H0.5C0.223858 6.5 0 6.72386 0 7C0 7.27614 0.223858 7.5 0.5 7.5H2.02467C2.25912 9.86229 4.13773 11.741 6.5 11.9755V13.5C6.5 13.7761 6.72386 14 7 14C7.27614 14 7.5 13.7761 7.5 13.5V11.9755C9.86227 11.741 11.7409 9.86229 11.9753 7.5H13.4999C13.7761 7.5 13.9999 7.27614 13.9999 7C13.9999 6.72386 13.7761 6.5 13.4999 6.5H11.9753C11.7407 4.13788 9.86215 2.2594 7.5 2.02487V0.5ZM7 11.0002C4.79086 11.0002 3 9.20932 3 7.00018C3 4.79104 4.79086 3.00018 7 3.00018C9.20914 3.00018 11 4.79104 11 7.00018C11 9.20932 9.20914 11.0002 7 11.0002Z" fill="#06EFC5" />
                  </svg> */}
                  <img src={UtilityFunctions.getImageURL() + "current-location.svg"} alt="" />
                </button>
                <span className="input-group-text">
                  <img src={UtilityFunctions.getImageURL() + "location-icon-blank.svg"} alt="" />
                  <img src={UtilityFunctions.getImageURL() + "location-icon-fill.svg"} alt="" />
                  {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                      <path d="M5.84328 4.56837C9.24369 1.16796 14.7568 1.16796 18.1573 4.56837C21.5577 7.96878 21.5577 13.4819 18.1573 16.8823L16.9703 18.0562C16.0954 18.9149 14.9603 20.0188 13.5644 21.3684C12.6922 22.2118 11.3084 22.2117 10.4362 21.3682L6.94515 17.9723C6.50638 17.5415 6.13912 17.1782 5.84328 16.8823C2.44287 13.4819 2.44287 7.96878 5.84328 4.56837ZM17.0966 5.62903C14.282 2.81441 9.71856 2.81441 6.90394 5.62903C4.08931 8.44366 4.08931 13.0071 6.90394 15.8217L8.39101 17.2891C9.20992 18.0906 10.2394 19.091 11.4791 20.29C11.7698 20.5711 12.231 20.5712 12.5218 20.2901L15.9167 16.9886C16.3856 16.5283 16.779 16.1393 17.0966 15.8217C19.9112 13.0071 19.9112 8.44366 17.0966 5.62903ZM12.0003 7.99903C13.658 7.99903 15.0018 9.34287 15.0018 11.0006C15.0018 12.6583 13.658 14.0021 12.0003 14.0021C10.3426 14.0021 8.99871 12.6583 8.99871 11.0006C8.99871 9.34287 10.3426 7.99903 12.0003 7.99903ZM12.0003 9.49903C11.171 9.49903 10.4987 10.1713 10.4987 11.0006C10.4987 11.8299 11.171 12.5021 12.0003 12.5021C12.8295 12.5021 13.5018 11.8299 13.5018 11.0006C13.5018 10.1713 12.8295 9.49903 12.0003 9.49903Z" fill="white" />
                    </g>
                  </svg> */}
                  {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.157 16.8822L16.97 18.0561C16.0952 18.9148 14.96 20.0187 13.5642 21.3683C12.6919 22.2117 11.3081 22.2115 10.436 21.3681L6.9449 17.9722C6.50614 17.5414 6.13887 17.1781 5.84303 16.8822C2.44262 13.4818 2.44262 7.96866 5.84303 4.56825C9.24344 1.16784 14.7566 1.16784 18.157 4.56825C21.5574 7.96866 21.5574 13.4818 18.157 16.8822ZM14.5002 10.9999C14.5002 9.61912 13.3808 8.49974 12 8.49974C10.6192 8.49974 9.49982 9.61912 9.49982 10.9999C9.49982 12.3808 10.6192 13.5001 12 13.5001C13.3808 13.5001 14.5002 12.3808 14.5002 10.9999Z" fill="#38BBF4" />
                  </svg> */}
                </span>
              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
      </form>
      <form onSubmit={submitHandler}>
        <div className="form-wrap experience-desing mt-0">
          <div className="v-form-width">
            <div className="form-floating1 mb-3">
              <div className="jobsalary-girdset">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <p className="defind-number">
                    <span>{value <= 100 ? ((Math.round(value * 10)) / 10) : "100+"}</span>
                    {STRING_CONSTANTS.employeeProfileCompletionPages.step5.kms}
                  </p>
                  <div className="center-arrow">
                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.10204 8C5.0281 8 4.45412 9.2649 5.16132 10.0731L10.6831 16.3838C11.3804 17.1806 12.6199 17.1806 13.3172 16.3838L18.839 10.0731C19.5462 9.2649 18.9722 8 17.8983 8H6.10204Z" fill="#242424" />
                    </svg> */}
                    <img src={UtilityFunctions.getImageURL() + "down-arrow-fill.svg"} alt="" />
                  </div>
                  <div className="line-svg">
                    <img src={UtilityFunctions.getImageURL() + "line-white-fill.svg"} alt="" />
                    {/* <svg width="361" height="10" viewBox="0 0 391 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M390.5 1H224.276C219.193 1 214.21 2.41743 209.888 5.09307V5.09307C201.072 10.5505 189.928 10.5505 181.112 5.09307V5.09307C176.79 2.41743 171.807 1 166.724 1H0" stroke="url(#paint0_linear_6101_2166)" />
                      <defs>
                        <linearGradient id="paint0_linear_6101_2166" x1="0" y1="1" x2="390" y2="1" gradientUnits="userSpaceOnUse">
                          <stop stopColor="white" stopOpacity="0" />
                          <stop offset="0.255208" stopColor="white" />
                          <stop offset="0.746521" stopColor="white" />
                          <stop offset="1" stopColor="white" stopOpacity="0" />
                        </linearGradient>
                      </defs>
                    </svg> */}
                  </div>
                  <div
                    className="left-right-arrow justify-content-center"
                    style={{
                      display: "flex",
                      height: '99px',
                      width: '390.55px'
                    }}
                  >
                    <button
                      style={{
                        background: 'none',
                        border: 'none',
                        height: '14.3px',
                      }}
                      type='button'
                      disabled={Math.round(value * 10) / 10 <= 0}
                      onClick={() => { setValue(prev => prev - 0.1) }}>
                      <img
                        alt=""
                        src={UtilityFunctions.getImageURL() + "slider-minus.svg"}
                      />
                    </button>
                    <SlideRule
                      cursor={
                        <div style={{
                          width: 1,
                          height: 22,
                          background: 'rgba(6, 239, 197, 1)'
                        }} />
                      }
                      numberStyle={{ color: 'white', size: '14px', family: 'Urbanist' }}
                      smallerMarkStyle={{ height: 15, width: 0.3 }}
                      markStyle={{ height: 22, width: 0.3 }}
                      width={320}
                      gap={6}
                      value={value}
                      min={0}
                      max={150}
                      step={0.1}
                      onChange={(e) => setValue(e)}
                    />
                    <button
                      style={{
                        background: 'none',
                        border: 'none',
                        height: '14.3px'
                      }}
                      type='button' disabled={value >= 150} onClick={() => { setValue(prev => prev + 0.1) }}>
                      <img
                        alt=""
                        src={UtilityFunctions.getImageURL() + "slider-plus.svg"}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>


            </div>
          </div>
        </div>
      </form>
      <div className="next-wrap">
        <button className={
          // abn?.length === 11 ? 
          "cricle-next"
          // : "cricle-next-inactive"
        }
          onClick={(e) => submitHandler(e)}
        >
          <img
            alt=""
            src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
          />
        </button>
      </div>
    </>
  )
}

export default withRouter(Location)