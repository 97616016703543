import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import ReactPlayer from 'react-player/lazy'
import ValidationErrorToast from "../../components/validationErrorToast";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { cookieService, userService } from "../../_services";


const IntroductoryVideo = (props) => {
  const [videoSrc, setVideoSrc] = useState(null);
  const vidRef = useRef<any>(null);
  const viewRef = useRef<any>(null);
  useEffect(() => {
    if (props?.userData) {
      setVideoSrc(props?.userData?.introductoryVideo)
    }
  }, [props])

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.persist()
    if (event.target.files[0].size > 157286400) {
      ValidationErrorToast("File Size Exceeded", "File size cannot exceed 150mb")
      setVideoSrc("")
      vidRef.current.value = null;
    }
    else {
      setVideoSrc(URL.createObjectURL(event.target.files[0]))
    };
    return;
  };
  const handleLoadedMetadata = () => {
    const video = viewRef.current;
    if (!video) return;
    if (video.duration > 30) {
      ValidationErrorToast("Video length exceeded", "Video length cannot be greater than 30 seconds")
      setVideoSrc("")
      vidRef.current.value = null;
    }
    else return;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    // Video has been added by the user
    if (videoSrc && !UtilityFunctions.isValidURL(videoSrc)) {
      let formData = new FormData();
      formData.append("introductoryVideo", vidRef?.current?.files[0])
      document.body.classList.add("loading-indicator");
      userService.userProfile(formData)
        .then(res => {
          cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res)
          props.nav.next();
        })
        .catch(e => ValidationErrorToast(e[0], e[1]))
    }
    // Exisiting video has been removed by the user
    else if (!vidRef?.current?.files[0] && !videoSrc && UtilityFunctions.isValidURL(props?.userData?.introductoryVideo)) {
      userService.userProfile({ "introductoryVideo": null, "thumbnail": null })
        .then(res =>
          cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res)
        )
        .catch(e => ValidationErrorToast(e[0], e[1]))
      props.nav.next()
    }
    // Video already exists and no changes have been made
    else props.nav.next();
  };
  // console.log(!vidRef?.current?.files[0], UtilityFunctions.isValidURL(props?.userData?.introductoryVideo))
  return (
    <>
      <div className='top-divde-spa'>
        <span>
          <i
            className="fa fa-angle-left text-white d-flex justify-content-between"
            aria-hidden="true"
            onClick={() => {
              props?.nav?.back()
            }}
          ></i>
        </span>
        <span className="top-skip">
          <p className="mb-0" onClick={() => props.nav.next()}>Skip</p>
        </span>
        <div className="step-content signup-mar">
          <button type='button' disabled>
            {STRING_CONSTANTS.employeeProfileCompletionPages.step2.title}
          </button>
          <p>
            {STRING_CONSTANTS.employeeProfileCompletionPages.step2.subtitle}
          </p>
          <div className="step-text">
            {STRING_CONSTANTS.employeeProfileCompletionPages.step2.step}
          </div>
        </div>
      </div>
      <form
      // onSubmit={abnSubmitHandler}
      >
        <div className="form-wrap">
          <div className='v-form-width'>
            <div className="form-floating mb-3">
              <div className="custom-file-upload">
                {videoSrc ?
                  <>
                    <button className="mycrossbut" type="button" onClick={() => { vidRef.current.value = null; setVideoSrc(null) }}>
                      {/* <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.89705 3.05379L2.96967 2.96967C3.23594 2.7034 3.6526 2.6792 3.94621 2.89705L4.03033 2.96967L7 5.939L9.96967 2.96967C10.2626 2.67678 10.7374 2.67678 11.0303 2.96967C11.3232 3.26256 11.3232 3.73744 11.0303 4.03033L8.061 7L11.0303 9.96967C11.2966 10.2359 11.3208 10.6526 11.1029 10.9462L11.0303 11.0303C10.7641 11.2966 10.3474 11.3208 10.0538 11.1029L9.96967 11.0303L7 8.061L4.03033 11.0303C3.73744 11.3232 3.26256 11.3232 2.96967 11.0303C2.67678 10.7374 2.67678 10.2626 2.96967 9.96967L5.939 7L2.96967 4.03033C2.7034 3.76406 2.6792 3.3474 2.89705 3.05379L2.96967 2.96967L2.89705 3.05379Z" fill="#100027" />
                      </svg> */}
                      <img src={UtilityFunctions.getImageURL() + "cross-white-bg-blue.svg"} alt="" />
                    </button>
                    <div className="videos_react_chet">
                      <video
                        controls
                        style={{
                          width: '100%',
                          height: '100%'
                        }}
                        src={videoSrc}
                        ref={viewRef}
                        onLoadedMetadata={handleLoadedMetadata}
                      >
                      </video>
                      {/* <ReactPlayer
                        controls={true}
                        width='100%'
                        height='100%'
                        light={true}
                        url={videoSrc}
                      /> */}
                    </div>
                  </> :
                  <label htmlFor='video_id' style={{ cursor: "pointer" }} >
                    {/* <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.82869 0.206923L9.94531 0.200195C10.4581 0.200195 10.8808 0.586236 10.9386 1.08357L10.9453 1.2002V8.2002H17.9453C18.4581 8.2002 18.8808 8.58624 18.9386 9.08357L18.9453 9.2002C18.9453 9.71303 18.5593 10.1357 18.0619 10.1935L17.9453 10.2002H10.9453V17.2002C10.9453 17.713 10.5593 18.1357 10.0619 18.1935L9.94531 18.2002C9.43248 18.2002 9.00981 17.8142 8.95204 17.3168L8.94531 17.2002V10.2002H1.94531C1.43248 10.2002 1.00981 9.81415 0.95204 9.31682L0.945312 9.2002C0.945312 8.68736 1.33135 8.26469 1.82869 8.20692L1.94531 8.2002H8.94531V1.2002C8.94531 0.687359 9.33135 0.264688 9.82869 0.206923L9.94531 0.200195L9.82869 0.206923Z" fill="#38BBF4" />
                    </svg> */}
                    <img src={UtilityFunctions.getImageURL() + "add_img.svg"} alt="" />
                    <p>{STRING_CONSTANTS.employeeProfileCompletionPages.step2.upload_video}</p>
                  </label>
                }
                <input
                  type="file"
                  ref={vidRef}
                  style={{ display: "none" }}
                  id="video_id"
                  max="150mb"
                  accept=".mov, .mp4"
                  onChange={(e) => {
                    handleChange(e)
                  }} />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="next-wrap">
        <button className={
          // videoSrc ?
          "cricle-next"
          // : "cricle-next-inactive"
        }
          onClick={(e) => submitHandler(e)}
        >
          <img
            alt=""
            src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
          />
        </button>
      </div>
    </>
  )
}

export default withRouter(IntroductoryVideo)