import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../store";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { cookieService } from "../_services";
import { useIsOnline } from "react-use-is-online";

export const isNotEmpty = (data: any) => {
  return (
    data !== null &&
    data !== undefined &&
    data !== "" &&
    typeof data === "object" &&
    Object.keys(data).length >= 1
  );
};

export const isNotEmptyText = (text: any) => {
  return text;
};

const AppChat2 = (props) => {
  const state = useSelector((state: State) => state);
  const chatTrigger = state?.chatReducer?.trigger_chat_tab;
  const dispatch = useDispatch();
  const { closeOnlineOfflineSocket, triggerChatTab, reconnectInsideSocket } =
    bindActionCreators(actionCreators, dispatch);
  // const ws = useRef<any>(null);
  const params = props?.params;
  const path: string = props?.path;
  const CHAT_URL = process.env.REACT_APP_SOCKETURL;
  const [lastPendingMessage, setLastPendingMessage] = useState();
  const [receivedMessage, setReceivedMessage] = useState();
  const [lastError, setLastError] = useState("");
  const [timer, setTimer] = useState(0); // 25 minutes
  const [start, setStart] = useState(false);
  const [ws, setWebSocket] = useState(null);
  const webSocketRef = useRef(null)
  const tick = useRef<any>(null);
  const isOpenRef = useRef(false);
  const firstTimeRender = useRef(false);
  const timeoutRef = useRef<any>(null);
  const timerRef = useRef<any>(null);
  const isUserLoggedIn = cookieService.getCookie(
    STRING_CONSTANTS.cookieStrings.token
  );
  const userData = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user);
  const { isOnline, isOffline, error } = useIsOnline();

  // useEffect(() => {
  //   setTimeout(() => {
  //     setStart(true);
  //   }, 2000);
  //   return () => {
  //     ws?.current?.close();
  //     ws.current = null;
  //     isOpenRef.current = false;
  //     firstTimeRender.current = false;
  //     clearTimeout(timeoutRef.current);
  //     clearInterval(tick?.current);
  //   };
  // }, []);

  useEffect(() => {
    connectWebSocket(); // Initial connection

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      if (webSocketRef?.current) {
        webSocketRef?.current?.close();
      }
    };
  }, []);

  useEffect(() => {
    // Periodically check the WebSocket connection and reconnect if necessary
    const reconnectInterval = setInterval(reconnectWebSocket, 5000);

    // Reconnect the socket every 480 seconds (8 minutes)
    const socketReconnectInterval = setInterval(
      () => connectWebSocket(),
      480000
    );
    const handleTabClose = (e) => {
      if (webSocketRef?.current) {
        webSocketRef?.current?.close();
      }
    };


    const handleInternetConnectionChange = () => {
      if (!navigator.onLine) {
        // Internet connection lost
        console.log('Internet connection lost');
        if (webSocketRef?.current) {
          webSocketRef?.current?.close();
          setWebSocket(null);
          webSocketRef.current = null
        }
      } else {
        // Internet connection restored
        console.log('Internet connection restored');
        reconnectWebSocket();
      }
    };

    window.addEventListener('online', handleInternetConnectionChange);
    window.addEventListener('offline', handleInternetConnectionChange);
    window.addEventListener('beforeunload', handleTabClose);

    // Clean up the intervals when the component unmounts
    return () => {
      clearInterval(reconnectInterval);
      clearInterval(socketReconnectInterval);
      window.removeEventListener('online', handleInternetConnectionChange);
      window.removeEventListener('offline', handleInternetConnectionChange);
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [webSocketRef?.current]);


  // useEffect(() => {
  //   // Close the WebSocket connection when the tab is closed
  //   const handleTabClose = (e) => {
  //     if (webSocketRef?.current) {
  //       webSocketRef?.current?.close();
  //     }
  //   };

  //   window.addEventListener('beforeunload', handleTabClose);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleTabClose);
  //   };
  // }, [webSocketRef?.current]);

  // useEffect(() => {
  //   const handleTabFocus = () => {
  //     // Code to run when the tab gains focus
  //  console.log(webSocketRef?.current?.readyState,WebSocket.OPEN)
  //     reconnectWebSocket()
  //     if ( webSocketRef?.current?.readyState === WebSocket.OPEN) {
  //       console.log("8765redfghjhgfdfghj")
  //       webSocketRef?.current?.send(JSON.stringify({ type: "online_report_echo" }));
  //     }
  //   };

  //   // Add event listener for tab focus
  //   window.addEventListener('focus', handleTabFocus);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('focus', handleTabFocus);
  //   };
  // }, []);




  useEffect(() => {
    if (receivedMessage) {
      props?.onNewMessage?.(receivedMessage);
      if (props?.isCurrentUserMessage) {
        //this handle only one message (can't handle multiple messages)
        props?.onMessageSent();
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (chatTrigger == true) {
      triggerChatTab(false);
      // setTimeout(() => {
      //   reconnectWebSocket()
      // }, 3000);
      setTimeout(() => {

        if (ws?.current?.readyState === 1) {
          ws?.current?.send(JSON.stringify({ type: "online_report_echo" }));
        }

      }, 3000);
    }
  }, [chatTrigger]);

  const connectWebSocket = () => {
    let paramStrings = "";
    let finalPath = path;
    if (isNotEmptyText(path) && !path?.charAt(path?.length - 1).includes("/")) {
      finalPath = finalPath + "/";
    }

    if (isNotEmpty(params)) {
      Object.keys(params).map((item, index) => {
        if (isNotEmptyText(paramStrings)) {
          paramStrings = paramStrings + "&";
        } else {
          paramStrings = paramStrings + "?";
        }
        paramStrings = paramStrings + item + "=" + params[item];
      });
    }
    console.log(userData);
    if (
      (userData?.user?.type === STRING_CONSTANTS.userType.employee &&
        userData?.isAgeVerified == true) ||
      userData?.user?.type === STRING_CONSTANTS.userType.employer
    ) {

      // alert("jik")

      // alert("hi");
      if (webSocketRef?.current) {
        webSocketRef?.current.close();
      }
      try {


        const socket = new WebSocket(`${CHAT_URL}${finalPath}${paramStrings}`);
        reconnectInsideSocket(true);

        socket.onopen = () => {
          console.log("Connected to the WebSocket server");
          setWebSocket(socket);
          webSocketRef.current = socket
        };

        socket.onmessage = (e) => {
          const data = JSON.parse(e.data);
          setReceivedMessage(data);
        };

        socket.onclose = () => {
          console.log("WebSocket connection closed");
          setWebSocket(null);
          webSocketRef.current = null
        };
      } catch (error) {
        console.log("jhgfdxcvbjkjhgfdft65rt", error)
      }

    }
  };

  const reconnectWebSocket = () => {
    console.log(webSocketRef.current)
    if (!webSocketRef?.current || webSocketRef?.current?.readyState === WebSocket.CLOSED) {
      console.log('Reconnecting...');
      connectWebSocket();
    }
  };




  // useEffect(() => {
  //   if (start && isUserLoggedIn) {
  //     tick.current = setInterval(() => {
  //       //setTimer((timer) => timer + 1);
  //       timerRef.current = timerRef.current + 1;
  //       if (isUserLoggedIn) {
  //         if (timerRef.current >= 480) {
  //           // alert("jhgfds")
  //           clearInterval(tick?.current);
  //           ws?.current?.close();
  //           ws.current = null;
  //           initWebSocket();
  //           setTimeout(() => {
  //             //setTimer(0);
  //             timerRef.current = 0;
  //           }, 500);
  //           setTimeout(() => {
  //             setStart(false);
  //           }, 1000);
  //           setTimeout(() => {
  //             setStart(true);
  //           }, 2000);
  //         } else if (
  //           (timerRef.current % 5 == 0 && timerRef.current !== 5) ||
  //           timerRef.current == 2
  //         ) {
  //           if (isOpenRef.current == false && isOnline) {
  //             clearTimeout(timeoutRef.current);
  //             ws?.current?.close();
  //             ws.current = null;
  //             initWebSocket();

  //             timeoutRef.current = setTimeout(() => {
  //               ws?.current?.close();
  //               ws.current = null;
  //               initWebSocket();
  //             }, 50000);
  //             //console.log("764ertyugfghjhfdfghj",JSON.stringify(user))
  //           }
  //         }
  //       }
  //     }, 1000);
  //   } else {
  //     clearInterval(tick?.current);
  //     clearTimeout(timeoutRef.current);
  //   }
  // }, [start]);

  // useEffect(() => {
  //   if (isUserLoggedIn) {
  //     if (timer >= 480) {
  //       clearInterval(tick?.current);
  //       ws?.current?.close();
  //       ws.current = null;
  //       initWebSocket();
  //       setTimeout(() => {
  //         setTimer(0);
  //       }, 500);
  //       setTimeout(() => {
  //         setStart(false);
  //       }, 1000);
  //       setTimeout(() => {
  //         setStart(true);
  //       }, 2000);
  //     } else if ((timer % 5 == 0 && timer !== 5) || timer == 2) {
  //       if (isOpenRef.current == false && isOnline) {
  //         clearTimeout(timeoutRef.current);
  //         ws?.current?.close();
  //         ws.current = null;
  //         initWebSocket();

  //         timeoutRef.current = setTimeout(() => {
  //           ws?.current?.close();
  //           ws.current = null;
  //           initWebSocket();
  //         }, 50000);
  //         //console.log("764ertyugfghjhfdfghj",JSON.stringify(user))
  //       }
  //     }
  //   }
  // }, [timer]);

  // useEffect(()=>{

  //   setTimeout(() => {
  //     if(isOnline){
  //       if(checkOnlineStatus()){

  //       }
  //     }
  //   }, 5000);
  // },[isOnline])

  const checkOnlineStatus = async () => {
    try {
      const online = await fetch("/1pixel.png");
      return online.status >= 200 && online.status < 300; // either true or false
    } catch (err) {
      return false; // definitely offline
    }
  };

  // console.log(timer);

  // useEffect(() => {
  //   if (timerRef.current >= 20) {
  //     clearInterval(tick?.current);
  //     ws?.current?.close();
  //     ws.current = null;
  //     initWebSocket();
  //     setTimeout(() => {
  //      // setTimer(0);
  //      timerRef.current=0
  //     }, 500);
  //     setTimeout(() => {
  //       setStart(false);
  //     }, 1000);
  //     setTimeout(() => {
  //       setStart(true);
  //     }, 2000);
  //   }
  // }, [timerRef.current]);

  const toggleStart = (val) => {
    setStart(val);
  };

  const initWebSocket = (startTimer = false) => {
    let paramStrings = "";
    let finalPath = path;
    if (isNotEmptyText(path) && !path?.charAt(path?.length - 1).includes("/")) {
      finalPath = finalPath + "/";
    }

    if (isNotEmpty(params)) {
      Object.keys(params).map((item, index) => {
        if (isNotEmptyText(paramStrings)) {
          paramStrings = paramStrings + "&";
        } else {
          paramStrings = paramStrings + "?";
        }
        paramStrings = paramStrings + item + "=" + params[item];
      });
    }
    console.log(userData);
    if (
      (userData?.user?.type === STRING_CONSTANTS.userType.employee &&
        userData?.isAgeVerified == true) ||
      userData?.user?.type === STRING_CONSTANTS.userType.employer
    ) {
      if (ws.current == null) {
        // alert("jik")
        reconnectInsideSocket(true);
        // alert("hi");
        ws.current = new WebSocket(`${CHAT_URL}${finalPath}${paramStrings}`);
        console.log(ws.current);
        if (startTimer) {
          // Alert.alert('will reconnect after 10');
          setTimeout(() => {
            ws?.current?.close();
            ws.current = null;
            //  Alert.alert('reconnecting');
            initWebSocket();
          }, 10000);
        }
        initiateListeners();
      }
    }
  };

  const initiateListeners = () => {
    /**
     * Callback to trigger when the socket gets connected
     */
    ws.current.onopen = (e) => {
      firstTimeRender.current = false;
      isOpenRef.current = true;
      setTimeout(() => {
        // setTimer(0);
        timerRef.current = 0;
      }, 500);
      setTimeout(() => {
        setStart(false);
      }, 1000);
      setTimeout(() => {
        setStart(true);
      }, 2000);
    };

    /**
     * Callback to trigger when the socket gets disconnected
     */
    ws.current.onclose = () => {
      isOpenRef.current = false;
      //   openOnlineOfflineSocket(false)
      // setIsConnectionOpen(false);
      // // localStorage.setItem("socket_connection","disconnected")
      // console.log("onclose");
      // // alert('close');
      // setIsOpen(false);
    };

    /**
     * Callback to trigger when the socket throws error
     */
    ws.current.onerror = (e) => {
      // ws?.current?.close()
      isOpenRef.current = false;
      props?.onSocketError?.(e);
      setLastError(e.message);
      // openOnlineOfflineSocket(false)
      // setIsConnectionOpen(false);
      // // Alert.alert(JSON.stringify(e?.message));
      // props?.onSocketError?.(e);
      // setIsOpen(false);
      // setLastError(e.message);
    };

    /**
     * When the message is received in Component
     */
    ws.current.onmessage = (e) => {
      // console.log("data", e.data);
      // Alert.alert(JSON.stringify(e?.data));
      const data = JSON.parse(e.data);
      setReceivedMessage(data);
    };
  };

  // const sendSocketMessage = (payload: any) => {
  //   if (ws.current.readyState === 1) {
  //     ws.current.send(JSON.stringify(payload));
  //     setLastPendingMessage(null);
  //     // ToDo: Check if it really was success
  //   } else {
  //     props?.onSendingMessage(payload);
  //     setLastPendingMessage(payload);
  //   }
  // };

  // return  <h1 style={{color:"black"}}>{isOpenRef.current==true?"Inside socket connected":"Inside socket disconnected"}</h1>
  return null;
};
export default AppChat2;
