import React, { useEffect, useRef, useState } from "react";
import JobSidebar from "../pages/Dashboard/Home/JobSidebar";
import EmployeeSidebar from "../pages/Dashboard/Home/EmployeeSidebar";
import EmployeeFilterSidebar from "../pages/Dashboard/Home/EmployeeFilterSidebar";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators, State } from "../store";
import { bindActionCreators } from "redux";
import { Button, Modal } from "react-bootstrap";
import { UtilityFunctions } from "../utils/UtilityFunctions";
import { api } from "../middleware/api";
import ValidationErrorToast from "./validationErrorToast";
import SuccessToast from "./successToast";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { cookieService } from "../_services";

const Sidebar = (props) => {
    const [show, setShow] = useState(false)
    const [flaglist, setFlagList] = useState([])
    const [flagReasons, setFlagReasons] = useState([])
    const [selectedUser, setSelectedUser] = useState<any>()
    const dispatch = useDispatch();
    const state = useSelector((state: State) => state);
    const { setSidebar, setRemoveEmployee } = bindActionCreators(actionCreators, dispatch);
    const [flag, setFlag] = useState(true);
    const [userExists, setUserExists] = useState(true)
    const [disableChatButtons, setDisableChatButtons] = useState(false)
    const jobDetails = "job-details";
    const employeeDetails = "employee-details";
    const employeeFilter = "employee-filter";
    const closeModalRef = useRef(null)
    const isUserLoggedIn = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token)
    const userTypeCookieval = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)

    useEffect((() => {
        setTimeout(() => {
            if (props?.closeModal) {
                closeModalRef?.current?.click()
            }
        }, 500)
    }), [props])

    const getFlaggingReasons = () => {
        api.get('employee/flagging-reasons/', null)
            .then(res => setFlagList(res))
            .then(data => setShow(true))
            .catch(e => {
                ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_flag_error, STRING_CONSTANTS.validationString.req_retry)
            })
    }
    const flagSubmithandler = () => {
        let submitData = {}
        if (userTypeCookieval.user.type === STRING_CONSTANTS.userType.employer) {
            // for employer payload
            submitData = {
                "user": selectedUser.user.id,
                "reasons": flagReasons
            }
        } else {
            // for employee payload
            submitData = {
                "job": selectedUser.id,
                "reasons": flagReasons
            }
        }
        if (flagReasons.length) {
            api.post('flag/', submitData)
                .then((res) => {
                    // setRemoveEmployee(selectedUser)
                    // setDisableChatButtons(true)
                    setFlag(false)
                    setShow(false)
                    userTypeCookieval.user.type === STRING_CONSTANTS.userType.employer ?
                        SuccessToast(STRING_CONSTANTS.formLabelString.label_flag, STRING_CONSTANTS.validationString.req_flag) :
                        SuccessToast(STRING_CONSTANTS.formLabelString.label_flag, "You have successfully flagged the job")
                    // "You have successfully flagged the job"
                    // SuccessToast(STRING_CONSTANTS.formLabelString.label_flag, STRING_CONSTANTS.validationString.req_flag)
                })
                .catch(e => {
                    setShow(false)
                    ValidationErrorToast(e[0], e[1])
                })
        }

        else {
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_reason, STRING_CONSTANTS.validationString.req_reason)
        }
    }

    const checkHandler = (id, check) => {
        const index = flagReasons.indexOf(id);
        if (index > -1 && check === false) {
            const list = [...flagReasons]
            list.splice(index, 1)
            setFlagReasons([...list])
        }
        else setFlagReasons(prev => [...prev, id])
    }
    let getClass = () => {
        // if (state.common.sidebar_name) {

        // top background change popup on top
        // right-side-popup-level-pink
        // right-side-popup-level-perple

        if (state.common.sidebar_name == jobDetails)
            return 'jobs-modal jobs-modal-cross-desing modal right-sidepop fade right'
        else if (state.common.sidebar_name == employeeDetails)
            // add right-side-popup-level-perple to below class for advanced user
            return `modal right-sidepop cross-upper-chnge-design fade right right-side-popup-level-perple`
        //  show openrightWindow
        else if (state.common.sidebar_name == employeeFilter)
            return 'jobs-modal chat_list_popup chat-list-popup-cross modal right-sidepop fade right'
        else
            return 'modal right-sidepop fade right'
        // }
    }
    return (
        <>
            {state.common.show_sidebar &&
                <div className="chat-reqest-shado">

                </div>}
            <div className={getClass()}
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-labelledby="staticBackdropLabel">
                {state.common.show_sidebar &&
                    <button type="button"
                        ref={closeModalRef}
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                            setSidebar({ sidebar_name: '', show_sidebar: false })
                            props?.setCloseModal?.(false)
                        }}>
                        {/* <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.08489 4.10051C4.47541 3.70999 5.10857 3.70999 5.4991 4.10051L14.0001 12.6015L22.5012 4.10051C22.8917 3.70999 23.5249 3.70999 23.9154 4.10051C24.3059 4.49103 24.3059 5.1242 23.9154 5.51472L15.4143 14.0158L23.883 22.4845C24.2736 22.875 24.2736 23.5081 23.883 23.8987C23.4925 24.2892 22.8594 24.2892 22.4688 23.8987L14.0001 15.43L5.53144 23.8987C5.14091 24.2892 4.50775 24.2892 4.11722 23.8987C3.7267 23.5081 3.7267 22.875 4.11722 22.4845L12.5859 14.0158L4.08489 5.51472C3.69436 5.1242 3.69436 4.49103 4.08489 4.10051Z" fill="white" />
                        </svg> */}
                        <img src={UtilityFunctions.getImageURL() + "close-icon.svg"} width="20px" height="20px" alt="" />
                    </button>}
                {/* {console.log("userTypeCookieval?.verified", userTypeCookieval?.user?.isVerified)} */}
                {flag && userExists && isUserLoggedIn && userTypeCookieval?.user?.isVerified &&
                    <div className='flag-icon-dash' style={{ cursor: "pointer" }} onClick={() => getFlaggingReasons()}>
                        {/* <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.5 16.5V4.5H21.8915L18.3653 10.1004C18.2116 10.3446 18.2116 10.6554 18.3653 10.8996L21.8915 16.5H5.5ZM5.5 18H23.25C23.8402 18 24.1991 17.3498 23.8847 16.8504L19.8863 10.5L23.8847 4.14961C24.1991 3.65017 23.8402 3 23.25 3H4.75C4.33579 3 4 3.33579 4 3.75V24.25C4 24.6642 4.33579 25 4.75 25C5.16421 25 5.5 24.6642 5.5 24.25V18Z" fill="white" />
                        </svg> */}
                        <img src={UtilityFunctions.getImageURL() + "flag-icon-dash.svg"} alt="" />
                    </div>
                }

                <div className="modal-dialog">
                    {state.common.sidebar_name == jobDetails
                        && <JobSidebar
                            selectedJob={props.selectedJob}
                            searchSelected={props.searchSelected}
                            selectedJobID={props.selectedJobID}
                            getAllJobs={props.getAllJobs}
                            history={props.history}
                        />}

                    {state.common.sidebar_name == employeeDetails
                        && <EmployeeSidebar
                            setShowGuestAccessPrompt={props?.setShowGuestAccessPrompt}
                            userType={props?.userType}
                            selectedEmployee={props.selectedEmployee}
                            selectedJobID={props.selectedJobID}
                            getChatRequests={props.getChatRequests}
                            history={props.history}
                            setFlagReasons={setFlagReasons}
                            setSelectedUser={setSelectedUser}
                            flag={flag}
                            setFlag={setFlag}
                            disableChatButtons={disableChatButtons}
                            setUserExists={setUserExists}
                            setSelectedEmployee={props.setSelectedEmployee}
                            closeModalForcefully={() => closeModalRef?.current?.click()}
                        />}
                    {state.common.sidebar_name == employeeFilter
                        && <EmployeeFilterSidebar
                            userType={props?.userType}
                            history={props.history}
                            selectedJob={props.selectedJob}
                            setRequestSent={props.setRequestSent}
                            setRequestReceived={props.setRequestReceived}
                            setRequestDistance={props.setRequestDistance}
                            setClosest={props.setClosest}
                            closest={props.closest}
                            setSelectedJobID={props.setSelectedJobID}
                            setEmployeeObject={props.setEmployeeObject}
                            selectedJobID={props.selectedJobID}
                            jobList={props.jobList}
                            employeeJobList={props.employeeJobList}
                            requestSent={props.requestSent}
                            requestReceived={props.requestReceived}
                            requestDistance={props.requestDistance}
                            activeIndex={props?.activeIndex}
                        />}
                </div>
            </div>
            <Modal
                className="bg-transparent text-white flag-user-popup"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => setShow(false)}
            >
                <Modal.Body>
                    <img
                        alt="logout"
                        src={UtilityFunctions.getImageURL() + "flag-user.svg"}
                    />
                    <h3 className="">{userTypeCookieval?.user?.type === STRING_CONSTANTS?.userType?.employer ? "Flag Employee?" : "Flag Job?"}
                    </h3>
                    <p>
                        Please provide a reason for flagging and the user will no longer be shown.
                    </p>
                    <div>
                        <div className="main-scroling">
                            {
                                flaglist.map((reason) =>
                                    <div className="sho-pchange checkbox-design d-flex gap-3 align-item-center" key={reason?.id}>
                                        <div className="input-w mycheckbox">
                                            <input
                                                type="checkbox"
                                                defaultChecked={flagReasons.includes(reason.id)}
                                                id={reason?.id}
                                                name={reason?.reason}
                                                onChange={(e) => checkHandler(reason.id, e.target.checked)}
                                            />
                                            <label htmlFor={reason?.id}></label>
                                        </div>
                                        <span className="text-start">{reason?.reason}</span>
                                    </div>)}
                        </div>
                        <Button className="border-0 btn-block w-100"
                            variant="primary"
                            // data-bs-dismiss="modal"
                            // aria-label="Close"
                            onClick={() => flagSubmithandler()
                            }
                        >
                            Submit
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Sidebar;