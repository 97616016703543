import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ValidationErrorToast from "../../components/validationErrorToast";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import { cookieService, userService } from "../../_services";
import { isEmpty } from 'lodash';
import Logout from "../../components/logout";
import { Data } from "../../_services/collections";

const ABNValidation = (props: any) => {
    const [abn, setABN] = useState("");
    const [btnClick, setBtnClick] = useState(false);
    const [show, setShow] = useState(false);

    const abnInputHandler = event => {
        const result = event.target.value.replace(/\D/g, '');
        setABN(result);
    };

    useEffect(() => {
        let user = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)
        setABN((user as Data)?.abn);
    }, [])

    //MADE ABN OPTIONAL AS PER CR
    const abnSubmitHandler = (e) => {
        e.preventDefault();
        // setBtnClick(true);
        // if (UtilityFunctions.isEmptyOrNullOrUndefined(abn)) {
        //     ValidationErrorToast('Invalid ABN', "This field can't be empty");
        // }
        // else if (abn.length < 11) {
        //     ValidationErrorToast('Invalid ABN', "Ensure this field has no lesser than 11 characters");
        // }
        // else 
        if (abn?.length) {
            userService
                .userProfile({ "abn": `${abn}` })
                .then(() => {
                    props?.nav?.next();
                })
                .catch((e) => {
                    // props?.nav?.next();
                    ValidationErrorToast(e[0], e[1])
                })
        }
        else if (!abn?.length) {
            userService
                .userProfile({ "abn": null })
                .then(() => {
                    props?.nav?.next();
                })
                .catch((e) => {
                    // props?.nav?.next();
                    ValidationErrorToast(e[0], e[1])
                    // console.log('no abn entered')
                })
        }
    }

    return <>
        <div className='top-divde-spa'>
            <span>
                <i
                    className="text-white d-flex justify-content-between"
                    aria-hidden="true"
                    onClick={() => setShow(true)}
                >
                    <img src={UtilityFunctions.getImageURL() + "cross-icon-white.svg"} width={'22px'} height={'22px'} alt="" />
                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.2097 4.3871L4.29289 4.29289C4.65338 3.93241 5.22061 3.90468 5.6129 4.2097L5.70711 4.29289L12 10.585L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L13.415 12L19.7071 18.2929C20.0676 18.6534 20.0953 19.2206 19.7903 19.6129L19.7071 19.7071C19.3466 20.0676 18.7794 20.0953 18.3871 19.7903L18.2929 19.7071L12 13.415L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L10.585 12L4.29289 5.70711C3.93241 5.34662 3.90468 4.77939 4.2097 4.3871L4.29289 4.29289L4.2097 4.3871Z" fill="white" />
                    </svg> */}
                </i>
            </span>
            <Logout
                show={show}
                handleClose={() => setShow(false)}
            />
            <div className="step-content signup-mar">
                <button type='button' disabled>
                    {STRING_CONSTANTS.employerProfileCompletionPages.abn_validation.trading_details}
                </button>
                <p>{STRING_CONSTANTS.employerProfileCompletionPages.abn_validation.enter_abn}</p>
                <div className="step-text">
                    {STRING_CONSTANTS.employerProfileCompletionPages.abn_validation.step}
                </div>
            </div>
        </div>
        <form onSubmit={abnSubmitHandler}>
            <div className="form-wrap">
                <div className='v-form-width'>
                    <div className="form-floating mb-3">
                        <input type='text' className={`form-control ${btnClick && isEmpty(abn) ? 'error' : ""}`} placeholder='Enter Business ABN' value={abn} onChange={(e) => { abnInputHandler(e); setBtnClick(false); }}></input>
                        <label htmlFor="floatingInput">{STRING_CONSTANTS.formLabelString.label_abn_plc}</label>
                        <span className="input-group-text">
                            <img src={UtilityFunctions.getImageURL() + "hashtag.svg"} alt="" />
                            <img src={UtilityFunctions.getImageURL() + "hashtag-fill.svg"} alt="" />
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.5">
                                    <path d="M10.9869 2.88953C11.0639 2.48254 10.7964 2.09019 10.3894 2.0132C9.98238 1.93622 9.59003 2.20374 9.51305 2.61074L8.49396 7.99815L3.74965 8.00037C3.33544 8.00056 2.99981 8.3365 3 8.75072C3.00019 9.16493 3.33614 9.50056 3.75035 9.50037L8.2102 9.49828L7.26445 14.498L2.74965 14.5001C2.33544 14.5003 1.99981 14.8363 2 15.2505C2.00019 15.6647 2.33614 16.0003 2.75035 16.0001L6.98068 15.9981L6.01302 21.1137C5.93603 21.5207 6.20356 21.9131 6.61055 21.99C7.01755 22.067 7.4099 21.7995 7.48688 21.3925L8.50742 15.9974L13.9814 15.9949L13.0131 21.1137C12.9361 21.5207 13.2036 21.9131 13.6106 21.99C14.0176 22.067 14.41 21.7995 14.4869 21.3925L15.5081 15.9942L20.2503 15.9919C20.6646 15.9917 21.0002 15.6558 21 15.2416C20.9998 14.8274 20.6639 14.4918 20.2496 14.4919L15.7919 14.494L16.7376 9.4943L21.2503 9.49219C21.6646 9.49199 22.0002 9.15605 22 8.74184C21.9998 8.32762 21.6639 7.99199 21.2496 7.99219L17.0214 7.99416L17.987 2.88953C18.064 2.48254 17.7964 2.09019 17.3894 2.0132C16.9824 1.93622 16.5901 2.20374 16.5131 2.61074L15.4946 7.99488L10.0207 7.99744L10.9869 2.88953ZM9.73693 9.49757L15.2109 9.49501L14.2651 14.4947L8.79118 14.4973L9.73693 9.49757Z" fill="white" />
                                </g>
                            </svg> */}
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.9848 3.1644C11.0756 2.61964 10.7076 2.10438 10.1629 2.01353C9.6181 1.92269 9.10284 2.29066 9.012 2.83542L8.15118 7.99747L3.99937 7.99992C3.44708 8.00024 2.99963 8.44822 2.99996 9.00051C3.00028 9.55279 3.44826 10.0002 4.00055 9.99992L7.81763 9.99767L7.15053 13.9981L2.99955 13.9999C2.44727 14.0002 1.99975 14.4481 2 15.0004C2.00025 15.5526 2.44816 16.0002 3.00045 15.9999L6.81699 15.9982L6.01034 20.8354C5.91949 21.3802 6.28747 21.8954 6.83223 21.9863C7.37699 22.0771 7.89225 21.7092 7.9831 21.1644L8.84475 15.9973L13.8203 15.9951L13.0136 20.8355C12.9228 21.3803 13.2908 21.8955 13.8356 21.9863C14.3803 22.0771 14.8956 21.7091 14.9863 21.1643L15.848 15.9942L20.0004 15.9923C20.5527 15.9921 21.0002 15.5442 21 14.9919C20.9998 14.4396 20.5518 13.9921 19.9996 13.9923L16.1814 13.994L16.8483 9.99235L21.0005 9.98991C21.5528 9.98958 22.0003 9.5416 22 8.98932C21.9996 8.43704 21.5517 7.98958 20.9994 7.98991L17.1817 7.99216L17.9863 3.16431C18.0771 2.61954 17.7091 2.10431 17.1644 2.01352C16.6196 1.92272 16.1044 2.29074 16.0136 2.83551L15.1539 7.99335L10.179 7.99628L10.9848 3.1644ZM9.84545 9.99648L14.8206 9.99355L14.1537 13.9949L9.1783 13.9972L9.84545 9.99648Z" fill="#38BBF4" />
                            </svg> */}
                        </span>
                    </div>
                </div>
            </div>
        </form>
        <div className="next-wrap">
            <button className={
                // abn?.length === 11 ? 
                "cricle-next"
                // : "cricle-next-inactive"
            }
                onClick={(e) => abnSubmitHandler(e)}>
                <img
                    alt=""
                    src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
                />
            </button>
        </div>
    </>
}


export default withRouter(ABNValidation);