import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import Logout from "../../components/logout";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import ImageCrop from "../../common/ui/ImageCrop";
import { DatePicker } from "@blueprintjs/datetime";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import ValidationErrorToast from "../../components/validationErrorToast";
import { api } from "../../middleware/api";
import { cookieService, userService } from "../../_services";
import moment from "moment";
const debounce = require("debounce-promise");


const CurrentEmployer = (props) => {
  // console.log(props.location.state.updateProfile)
  const [show, setShow] = useState(false);
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [isFromDateOpen, setIsFromDateOpen] = useState<boolean>(false);
  const [isToDateOpen, setIsToDateOpen] = useState<boolean>(false);
  const [hideProfile, setHideProfile] = useState(false);
  const fromDateRef = useRef<any>(null);
  const toDateRef = useRef<any>(null);
  const isProfileBeingUpdated = props?.location?.state?.updateProfile || false;
  const initialErrorState = { registered: false, unregistered: false }
  const [errorState, setErrorState] = useState(initialErrorState)

  const searchRef = useRef<HTMLInputElement>();
  const businessRef = useRef<HTMLInputElement>();
  const [businessName, setBusinessName] = useState("")
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [searchemployerList, setSearchEmployerList] = useState([]);
  const [currentEmployer, setCurrentEmployer] = useState<any>({});
  const [employerObjectID, setEmployerObjectID] = useState("");
  const [IsRegisteredEmployer, setIsRegisteredEmployer] = useState(true);

  useEffect(() => {
    if (searchTerm) {
      api.get(`employer/all/?search=${searchTerm}`, null)
        .then(res => setSearchEmployerList(res))
        .catch(e => { })
    }
  }, [searchTerm])

  // A FUNCTION THAT SETS DATA WHEN USER IS UPDATING THE PROFILE
  const setData = (localClicked = false) => {
    if (props?.userData?.experienceHistory?.length) {
      props?.userData?.experienceHistory?.map((item, index) => {
        if (item?.employerType === 'C') {
          if (item?.unregisteredEmployer) {
            setIsRegisteredEmployer(false)
            setBusinessName(item?.unregisteredEmployer)
          }
          if (localClicked) {
            item['employer'] = null;
          }
          setCurrentEmployer(item?.employer);
          setHideProfile(item?.isProfileHidden)
          setEmployerObjectID(item?.id)
          if (item?.startDate) {
            setFromDate(new Date(item?.startDate))
            // fromDateRef.current.value = item?.startDate
          }
          if (item?.endDate) {
            setToDate(new Date(item?.endDate))
            // toDateRef.current.value = item?.endDate
          }
        }
      })
    }
    if (localClicked) {
      setCurrentEmployer({})
    }
  }

  useEffect(() => {
    setData()
  }, [props?.userData?.experienceHistory])

  useEffect(() => {
    setSearchTerm("");
    if (searchRef?.current?.value) {
      searchRef.current.value = "";
    }
    else if (businessRef?.current?.value) {
      businessRef.current.value = ""
    }
  }, [IsRegisteredEmployer])

  const debounceClick = debounce((value) => {
    setSearchTerm(value)
  }, 600);

  const dateFormatter = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString().substring(0, 10);
  }

  // A FUNCTION THAT VALIDATES DATE RANGE AS SOON AS USER SELECTS A DATE FROM THE CALENDARS
  const onDateChangeHandler = (arg, date) => {
    if (arg != '' && date === "from") {
      ValidationErrorToast('Date Select', 'Please select a date from the dropdown calendar');
      // fromDateRef.current.value = ''
      setIsFromDateOpen(true)
    }
    else if (arg != '' && date === "to") {
      ValidationErrorToast('Date Select', 'Please select a date from the dropdown calendar');
      // toDateRef.current.value = ''
      setIsToDateOpen(true)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault();
    if (!currentEmployer?.id && IsRegisteredEmployer) {
      ValidationErrorToast("Current Employer", "Please select an employer before proceeding")
      setErrorState(prev => { return { ...prev, registered: true } })
    }
    else if (!IsRegisteredEmployer && businessRef.current.value === "") {
      ValidationErrorToast("Date Range", "Please select a valid Business Name before proceeding")
      setErrorState(prev => { return { ...prev, unregistered: true } })
    }
    else if (!fromDate) {
      ValidationErrorToast("Start Date", "Please select start date")
    }
    else if (moment(fromDate) > moment(toDate)) {
      ValidationErrorToast("Date Range", "Please select a valid date range")
    }
    else submitDataHandler();
  }

  const submitDataHandler = () => {
    const patchObjectForRegisteredEmployer = {
      experienceHistory: [
        {
          id: employerObjectID, // if an employer already exists
          employer: `${currentEmployer?.id}`,
          isProfileHidden: hideProfile,
          startDate: moment(fromDate).format("YYYY-MM-DD") || null,
          endDate: toDate ? moment(toDate).format("YYYY-MM-DD") : null,
          unregisteredEmployer: null,
          employerType: "C"
        }
      ]
    }

    const patchObjectForUnregisteredEmployer = {
      experienceHistory: [
        {
          id: employerObjectID, // if an employer already exists
          employer: null,
          isProfileHidden: false,
          startDate: moment(fromDate).format("YYYY-MM-DD") || null,
          endDate: toDate ? moment(toDate).format("YYYY-MM-DD") : null,
          unregisteredEmployer: `${businessName}`,
          employerType: "C"
        }
      ]
    }

    if (!employerObjectID) {
      delete patchObjectForRegisteredEmployer?.experienceHistory?.[0]?.id
      delete patchObjectForUnregisteredEmployer?.experienceHistory?.[0]?.id
    }
    document.body.classList.add("loading-indicator");
    userService.userProfile(IsRegisteredEmployer ? patchObjectForRegisteredEmployer : patchObjectForUnregisteredEmployer)
      .then(
        res => {
          document.body.classList.remove("loading-indicator");
          cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res?.data)
          props.nav.next()
        }
      )
      .catch(e => {
        document.body.classList.remove("loading-indicator");
        ValidationErrorToast(e[0], e[1])
      })

  }
  return (
    <>
      <div className='top-divde-spa'>
        <span>
          {isProfileBeingUpdated ?
            <i
              className="fa fa-angle-left text-white d-flex justify-content-between"
              aria-hidden="true"
              onClick={() => {
                isProfileBeingUpdated ?
                  props.history.push("/dashboard/settings/", { tab: "updateProfile" }) :
                  props?.nav?.back()
              }}
            >
            </i>
            :
            <i
              className="text-white d-flex justify-content-between"
              aria-hidden="true"
              onClick={() => setShow(true)}
            >
              <img src={UtilityFunctions.getImageURL() + "close-icon.svg"} width={'24px'} height={'24px'} alt="" />
              {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.2097 4.3871L4.29289 4.29289C4.65338 3.93241 5.22061 3.90468 5.6129 4.2097L5.70711 4.29289L12 10.585L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L13.415 12L19.7071 18.2929C20.0676 18.6534 20.0953 19.2206 19.7903 19.6129L19.7071 19.7071C19.3466 20.0676 18.7794 20.0953 18.3871 19.7903L18.2929 19.7071L12 13.415L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L10.585 12L4.29289 5.70711C3.93241 5.34662 3.90468 4.77939 4.2097 4.3871L4.29289 4.29289L4.2097 4.3871Z" fill="white" />
              </svg> */}
            </i>
          }
        </span>
        <Logout
          show={show}
          handleClose={() => setShow(false)}
        />
        {!props.location.state?.updateProfile && <span className="top-skip">
          <p className="mb-0" onClick={() => props.nav.next()}>Skip</p>
        </span>}
        <div className="step-content signup-mar">
          <button type='button' disabled>
            {STRING_CONSTANTS.employeeProfileCompletionPages.step8.title}
          </button>
          <p>
            {STRING_CONSTANTS.employeeProfileCompletionPages.step8.subtitle}
          </p>
          <div className="step-text">
            {!isProfileBeingUpdated ? STRING_CONSTANTS.employeeProfileCompletionPages.step8.step : ""}
          </div>
        </div>
      </div>
      <form
      // onSubmit={abnSubmitHandler}
      >
        <div className="form-wrap business-detailss">
          <div className="v-form-width min-height-auto">
            <div className="form-floating mb-3 label-dark">

              {IsRegisteredEmployer ?
                <>
                  <input type='text'
                    className={`form-control search-icon-error ${errorState.registered ? "error" : ""}`}
                    placeholder="Search employer..."
                    ref={searchRef}
                    onChange={(e) => {
                      setErrorState(prev => { return { ...prev, registered: false } })
                      debounceClick(e.target.value)
                    }}
                  />
                  <label htmlFor="floatingInput">
                    {STRING_CONSTANTS.employeeProfileCompletionPages.step9.search_employer_ph}
                  </label>
                  <span className="input-group-text">
                    <img src={UtilityFunctions.getImageURL() + "search-with-shadow.svg"} alt="" />
                    <img src={UtilityFunctions.getImageURL() + "search-with-shadow-fill.svg"} alt="" />
                    {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                      <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                      <defs>
                        <filter id="filter0_f_4_8" x="-3" y="-3" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                          <feFlood floodOpacity="0" result="BackgroundImageFix" />
                          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_4_8" />
                        </filter>
                      </defs>
                    </svg> */}
                    {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_f_4_8)">
                        <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                        <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                      </g>
                      <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                      <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                      <defs>
                        <filter id="filter0_f_4_8" x="-3" y="-3" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                          <feFlood floodOpacity="0" result="BackgroundImageFix" />
                          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_4_8" />
                        </filter>
                      </defs>
                    </svg> */}
                  </span>
                </> :
                <>
                  <input type='text'
                    className={`form-control enter-buss ${errorState.unregistered ? "error" : ""}`}
                    placeholder="Enter Business Name"
                    value={businessName}
                    ref={businessRef}
                    maxLength={50}
                    onChange={(e) => {
                      setBusinessName(e.target.value)
                      setErrorState(prev => { return { ...prev, unregistered: false } })
                    }}
                  />
                  <label htmlFor="floatingInput">
                    {STRING_CONSTANTS.employeeProfileCompletionPages.step8.enter_business_name}
                  </label>
                  <span className="input-group-text">
                    <img src={UtilityFunctions.getImageURL() + "building-ico-form.svg"} alt="" />
                    <img src={UtilityFunctions.getImageURL() + "building-input-fill.svg"} alt="" />
                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.5">
                        <path d="M8.5 5.5C7.94772 5.5 7.5 5.94772 7.5 6.5C7.5 7.05228 7.94772 7.5 8.5 7.5C9.05229 7.5 9.5 7.05228 9.5 6.5C9.5 5.94772 9.05229 5.5 8.5 5.5ZM7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5C9.05229 12.5 9.5 12.9477 9.5 13.5C9.5 14.0523 9.05229 14.5 8.5 14.5C7.94772 14.5 7.5 14.0523 7.5 13.5ZM8.5 9C7.94772 9 7.5 9.44771 7.5 10C7.5 10.5523 7.94772 11 8.5 11C9.05229 11 9.5 10.5523 9.5 10C9.5 9.44771 9.05229 9 8.5 9ZM11 6.5C11 5.94772 11.4477 5.5 12 5.5C12.5523 5.5 13 5.94772 13 6.5C13 7.05228 12.5523 7.5 12 7.5C11.4477 7.5 11 7.05228 11 6.5ZM12 12.5C11.4477 12.5 11 12.9477 11 13.5C11 14.0523 11.4477 14.5 12 14.5C12.5523 14.5 13 14.0523 13 13.5C13 12.9477 12.5523 12.5 12 12.5ZM14.5 13.5C14.5 12.9477 14.9477 12.5 15.5 12.5C16.0523 12.5 16.5 12.9477 16.5 13.5C16.5 14.0523 16.0523 14.5 15.5 14.5C14.9477 14.5 14.5 14.0523 14.5 13.5ZM12 9C11.4477 9 11 9.44771 11 10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10C13 9.44771 12.5523 9 12 9ZM6.25 2C5.00736 2 4 3.00736 4 4.25V20.75C4 21.1642 4.33579 21.5 4.75 21.5H19.2528C19.667 21.5 20.0028 21.1642 20.0028 20.75V11.7493C20.0028 10.5067 18.9954 9.4993 17.7528 9.4993H16.5V4.25C16.5 3.00736 15.4926 2 14.25 2H6.25ZM5.5 4.25C5.5 3.83579 5.83579 3.5 6.25 3.5H14.25C14.6642 3.5 15 3.83579 15 4.25V10.2493C15 10.6635 15.3358 10.9993 15.75 10.9993H17.7528C18.167 10.9993 18.5028 11.3351 18.5028 11.7493V20H16.5V17.25C16.5 16.8358 16.1642 16.5 15.75 16.5H8.25C7.83579 16.5 7.5 16.8358 7.5 17.25V20H5.5V4.25ZM15 18V20H12.75V18H15ZM11.25 18V20H9V18H11.25Z" fill="white" />
                      </g>
                    </svg> */}
                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.75 2C5.50736 2 4.5 3.00736 4.5 4.25V20.75C4.5 21.1642 4.83579 21.5 5.25 21.5H7.5V17.25C7.5 16.8358 7.83579 16.5 8.25 16.5H15.75C16.1642 16.5 16.5 16.8358 16.5 17.25V21.5H18.75C19.1642 21.5 19.5 21.1642 19.5 20.75V11.7493C19.5 10.5067 18.4926 9.4993 17.25 9.4993H16.5V4.25C16.5 3.00736 15.4926 2 14.25 2H6.75ZM7.5 6.5C7.5 5.94772 7.94772 5.5 8.5 5.5C9.05228 5.5 9.5 5.94772 9.5 6.5C9.5 7.05228 9.05228 7.5 8.5 7.5C7.94772 7.5 7.5 7.05228 7.5 6.5ZM8.5 12.5C9.05228 12.5 9.5 12.9477 9.5 13.5C9.5 14.0523 9.05228 14.5 8.5 14.5C7.94772 14.5 7.5 14.0523 7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5ZM7.5 10C7.5 9.44772 7.94772 9 8.5 9C9.05228 9 9.5 9.44772 9.5 10C9.5 10.5523 9.05228 11 8.5 11C7.94772 11 7.5 10.5523 7.5 10ZM12 5.5C12.5523 5.5 13 5.94772 13 6.5C13 7.05228 12.5523 7.5 12 7.5C11.4477 7.5 11 7.05228 11 6.5C11 5.94772 11.4477 5.5 12 5.5ZM11 13.5C11 12.9477 11.4477 12.5 12 12.5C12.5523 12.5 13 12.9477 13 13.5C13 14.0523 12.5523 14.5 12 14.5C11.4477 14.5 11 14.0523 11 13.5ZM15.5 12.5C16.0523 12.5 16.5 12.9477 16.5 13.5C16.5 14.0523 16.0523 14.5 15.5 14.5C14.9477 14.5 14.5 14.0523 14.5 13.5C14.5 12.9477 14.9477 12.5 15.5 12.5ZM11 10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44772 13 10C13 10.5523 12.5523 11 12 11C11.4477 11 11 10.5523 11 10ZM15 21.5V18H12.7499V21.5H15ZM11.2499 21.5V18H9V21.5H11.2499Z" fill="#38BBF4" />
                    </svg> */}
                  </span>
                </>}
              {/* SEARCH TERMS EXISTS BUT SEARCH RESULTS ARE ZER0 */}
              {(searchTerm && !searchemployerList.length) ?
                <div className="filter-dropdonw-set-blank">
                  <div className="text-center">
                    <img src={UtilityFunctions.getImageURL() + "three-dots-blue.svg"} alt="" />
                    {/* <svg width="40" height="8" viewBox="0 0 40 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="4" cy="4" r="3.5" fill="white" fillOpacity="0.2" stroke="#38BBF4" />
                      <circle cx="20" cy="4" r="3.5" fill="white" fillOpacity="0.2" stroke="#38BBF4" />
                      <circle cx="36" cy="4" r="3.5" fill="white" fillOpacity="0.2" stroke="#38BBF4" />
                    </svg> */}
                    <p>{STRING_CONSTANTS.employeeProfileCompletionPages.step8.no_results_found} "{searchTerm}"</p>
                    <button type="button"
                      onClick={() => { }}>
                      {STRING_CONSTANTS.employeeProfileCompletionPages.step8.search_again}
                    </button>
                  </div>
                </div>
                :
                // SEARCH TERM AND SEARCH RESULTS BOTH EXIST
                (searchemployerList.length && searchTerm) ?
                  <ul className="list-unstyled ms-0 filter-dropdonw-set">
                    {searchemployerList.map(employer =>
                      <li key={employer?.id} onClick={() => {
                        setCurrentEmployer(employer);
                        setSearchTerm("");
                        searchRef.current.value = "";
                      }}>
                        <img src={employer?.logo || UtilityFunctions.getImageURL() + "bildeing-squre-img.png"} alt="" />
                        <span>
                          {employer?.businessName} ({employer?.tradingName})
                        </span>
                      </li>)}
                  </ul> :
                  <></>
              }
            </div>
            {currentEmployer?.id && IsRegisteredEmployer &&
              <ul className="current-employ-list list-unstyled ps-0 ms-0 text-white mb-3">
                <li className="d-flex align-items-center justify-content-between gap-3">
                  <span className="d-flex align-items-center gap-3">
                    <img
                      src={currentEmployer?.logo || UtilityFunctions.getImageURL() + "bildeing-squre-img.png"}
                      className="bilding-ig" alt=""
                    />
                    {currentEmployer?.tradingName}
                  </span>
                  <img
                    onClick={() => { setCurrentEmployer({}) }}
                    src={UtilityFunctions.getImageURL() + "Dismiss-cross-green.svg"}
                    className="cross-icon"
                    alt=""
                  />
                </li>
              </ul>}
            <div className="row gx-3">
              <div className="col-sm-6">
                <div className="form-floating mb-3 label-dark without-icon">
                  <input type='text'
                    style={{ caretColor: 'transparent', background: '#2b1d3f' }}
                    readOnly
                    className="form-control ps-2"
                    placeholder="Employed Since"
                    value={fromDate ? moment(fromDate).format("DD-MM-YYYY") : ""}
                    onClick={() => {
                      setIsFromDateOpen(prev => !prev)
                      setIsToDateOpen(false)
                    }}
                    // ref={fromDateRef}
                    onChange={e => {
                      onDateChangeHandler(e.target.value, "from")
                    }}
                  />
                  <label htmlFor="floatingInput" className="ps-2">
                    {STRING_CONSTANTS.employeeProfileCompletionPages.step8.employer_since}
                  </label>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-floating mb-3 label-dark without-icon">
                  <input type='text' className="form-control ps-2"
                    style={{ caretColor: 'transparent', background: '#2b1d3f' }}
                    readOnly
                    placeholder="Employed Till (optional)"
                    value={toDate ? moment(toDate).format("DD-MM-YYYY") : ""}
                    // ref={toDateRef}
                    onClick={() => {
                      setIsFromDateOpen(false)
                      setIsToDateOpen(prev => !prev)
                    }}
                    onChange={e => {
                      onDateChangeHandler(e.target.value, "to")
                    }}
                  />
                  <label htmlFor="floatingInput" className="ps-2">
                    {STRING_CONSTANTS.employeeProfileCompletionPages.step8.employer_till}
                  </label>
                </div>
              </div>
            </div>
            <div className="form-floating input-icon-right-side mb-3">
              {/* FROM DATE PICKER */}
              {isFromDateOpen && !isToDateOpen &&
                <div style={{ background: 'inherit', right: 0 }}>
                  <DatePicker
                    value={fromDate}
                    maxDate={new Date()}
                    onChange={e => setFromDate(e)}
                    footerElement={
                      <div className="colendbut d-flex">
                        <button type='button'
                          onClick={() => {
                            setFromDate(undefined)
                            // fromDateRef.current.value = ""
                            setIsFromDateOpen(false)
                          }
                          }
                        >
                          Cancel
                        </button>
                        <button type='button' className="rightbut"
                          onClick={() => {
                            // fromDateRef.current.value = fromDate ? dateFormatter(fromDate, 1) : ""
                            setIsFromDateOpen(false)
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    }
                  />
                </div>}
            </div>
            <div className="form-floating input-icon-right-side">
              {/* TO DATE PICKER */}
              {isToDateOpen && !isFromDateOpen && <div
                style={{ background: 'inherit', right: 0 }}>
                <DatePicker
                  maxDate={new Date()}
                  minDate={fromDate || new Date("2003-01-01")}
                  value={toDate || null}
                  onChange={e => setToDate(e)}
                  footerElement={
                    <div className="colendbut d-flex">
                      <button type='button'
                        onClick={() => {
                          setToDate(undefined)
                          // toDateRef.current.value = ""
                          setIsToDateOpen(false)
                        }}
                      >
                        Cancel
                      </button>
                      <button type='button' className="rightbut"
                        onClick={() => {
                          // toDateRef.current.value = toDate ? dateFormatter(toDate, 1) : ""
                          setIsToDateOpen(false)
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                  }
                />
              </div>}
            </div>
            {IsRegisteredEmployer &&
              <div className="hide-profile-employee d-flex align-items-center justify-content-between">
                <p className="mb-0">
                  {STRING_CONSTANTS.employeeProfileCompletionPages.step8.hide_profile}
                </p>
                <div className="radio-buton d-flex align-items-center gap-2 ">
                  <span className={
                    // !props.newestJob ? "text-white active" : 
                    "text-white"
                  }>No</span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={hideProfile}
                      onChange={e => setHideProfile(e.target.checked)}
                    />
                    <span className="slider round">
                    </span>
                  </label>
                  <span className={
                    "text-white"
                  }>Yes</span>
                </div>
              </div>}
          </div>
        </div>
      </form>
      <div className="cant-fin-emploer text-white">
        {IsRegisteredEmployer ?
          <p>
            <span>
              {STRING_CONSTANTS.employeeProfileCompletionPages.step8.cant_find_employer + " "}
            </span>
            <b onClick={() => {
              setData(true);
              setIsRegisteredEmployer(false);
            }}>
              {STRING_CONSTANTS.employeeProfileCompletionPages.step8.enter_name}
            </b>
          </p>
          :
          <p onClick={() => {
            setData(true);
            setIsRegisteredEmployer(true);
          }}>{STRING_CONSTANTS.employeeProfileCompletionPages.step8.search_employer}</p>
        }
      </div>
      <div className="next-wrap">
        <button className={
          (((currentEmployer?.id && IsRegisteredEmployer) || (!IsRegisteredEmployer || businessName))) && fromDate ?
            "cricle-next"
            : "cricle-next-inactive"
        }
          onClick={(e) => submitHandler(e)}
        >
          <img
            alt=""
            src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
          />
        </button>
      </div>
    </>
  )
}

export default withRouter(CurrentEmployer)