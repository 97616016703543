import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"

import ProfileWrapper from "../../../common/Profile-Wrapper";
import { userData } from "../../../utils/UserData";

import JobCompletion from "./completion"
import JobCategory from "./jobCategory";
import JobSubcategory from "./jobSubcategory";
import JobDescription from "./jobDescription";
import JobExperience from "./jobExperience";
import JobImage from "./jobImage";
import JobLocation from "./jobLocation";
import JobSalary from "./jobSalary";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators, AppDispatch, State } from "../../../store";
import { bindActionCreators } from "redux";
import { cookieService, userService } from "../../../_services";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import SubscriptionModal from "../Home/SubscriptionModal";
import { fetchSubscriptionChose } from "../../../store/reducers/subscriptionPlans/apiThunk";

const JobCreation = (props: any) => {
    const [step, setStep] = useState(1);
    const state = useSelector((state: State) => state);
    const dispatch = useDispatch<AppDispatch>();
    const { setEditJob, setJobDate } = bindActionCreators(actionCreators, dispatch);
    const [subsModalVisibility, setSubsModalVisibility] = useState(false);
    const userType = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user);

    const editJobObj = props?.location?.state

    const navigation = {
        next: function () {
            if (step !== 8) {
                setStep(prevState => prevState + 1)
            }
        },
        back: function () {
            if (step !== 1) {
                setStep(prevState => prevState - 1)
            }
        }
    }
    useEffect(() => {
        if (props?.location?.state?.editJob) {
            let reduxJobData = state?.newJob;
            let editJobData = props?.location?.state?.job
            let output = {}
            for (let dataR in reduxJobData) {
                for (let dataE in editJobData) {
                    if (dataR === dataE) {
                        output[dataR] = editJobData[dataE]
                    }
                }
            }
            setEditJob(output)
            setStep(3)
        }
    }, [])
    // useEffect(() => {
    //     const chosesubs = state.subscriptionPlans.choseSubscriptionPlan
    //     if (userType?.user?.type === STRING_CONSTANTS.userType.employer) {
    //         if (Object.keys(chosesubs).length === 0) {
    //             setSubsModalVisibility(true)
    //         } else {
    //             setSubsModalVisibility(false)
    //         }
    //     }
    // }, []);
    useEffect(() => {
        const fetchDataChose = async () => {
            try {
                const res = await dispatch(fetchSubscriptionChose());
                if (userType?.user?.type === STRING_CONSTANTS.userType.employer) {
                    if (Object.keys(res.payload).length === 0) {
                        setSubsModalVisibility(true);
                    } else {
                        setSubsModalVisibility(false);
                    }
                } else {
                    setSubsModalVisibility(false);
                }
            } catch (error) {
                console.error("An error occurred:", error);
            }
        };

        fetchDataChose();
    }, []);
    useEffect(() => { state.newJob.description && setJobDate(new Date(props?.location?.state?.job?.startFrom)) }, [])
    return (<>
        <div style={{ position: 'absolute' }}>
        </div>
        <ProfileWrapper
            module={'JobCreation'}
            history={props.history}
            nav={navigation}
            currentStep={step}
            backButtonDisabled={step === 8 ? true : false}
        >
            {step === 1 ? <JobCategory nav={navigation} history={props.history} /> : null}
            {step === 2 ? <JobSubcategory nav={navigation} /> : null}
            {step === 3 ? <JobImage nav={navigation} editJob={editJobObj} history={props.history} /> : null}
            {step === 4 ? <JobDescription nav={navigation} editJob={editJobObj} history={props.history} /> : null}
            {step === 5 ? <JobLocation nav={navigation} history={props.history} /> : null}
            {step === 6 ? <JobExperience nav={navigation} history={props.history} /> : null}
            {step === 7 ? <JobSalary nav={navigation} editJob={editJobObj} history={props.history} /> : null}
            {step === 8 ? <JobCompletion nav={navigation} editJob={editJobObj} history={props.history} /> : null}
        </ProfileWrapper>
        <SubscriptionModal
            subsModal={subsModalVisibility}
            setSubsModal={setSubsModalVisibility}
            history={props.history}

        />
    </>
    )
}


export default withRouter(JobCreation)