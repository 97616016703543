import { ActionType } from "../constants";
import { Dispatch } from "redux";
import { Action } from "../actions/index";

export const setSelectedPlan = (plan) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SELECTED_PLAN,
      item: plan,
    });
  };
};

export const setStripeClientSecret = (key) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CLIENT_SECRET,
      item: key,
    });
  };
};

export const setSignUpMobileNumber = (key) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_MOBILE,
      item: key,
    });
  };
};

export const resetState = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET,
      item: null,
    });
  };
};

export const setShowSideBarOnMobileView = (key) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SHOW_SIDEBAR,
      item: key,
    });
  };
};

export const setSidebar = (obj) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SIDEBAR,
      item: obj,
    });
  };
};

export const setJobCategory = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_JOB_CATEGORY,
      item: value,
    });
  };
};

export const setJobSubcategory = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_JOB_SUBCATEGORY,
      item: value,
    });
  };
};

export const setJobCover = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_JOB_COVER,
      item: value,
    });
  };
};

export const setJobTitle = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_JOB_TITLE,
      item: value,
    });
  };
};

export const setJobDate = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_JOB_DATE,
      item: value,
    });
  };
};

export const setJobDesc = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_JOB_DESC,
      item: value,
    });
  };
};

export const setJobLocation = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_JOB_LOCATION,
      item: value,
    });
  };
};

export const setJobExperience = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_JOB_EXPERIENCE,
      item: value,
    });
  };
};

export const setJobMinSalary = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_JOB_MINIMUM,
      item: value,
    });
  };
};

export const setJobMaxSalary = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_JOB_MAXIMUM,
      item: value,
    });
  };
};

export const setJobType = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_JOB_TYPE,
      item: value,
    });
  };
};

export const setJobRateType = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_RATE_TYPE,
      item: value,
    });
  };
};

export const setEditJob = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_EDIT_JOB,
      item: value,
    });
  };
};

export const setResetJob = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_RESET_JOB,
      item: null,
    });
  };
};

export const setUnreadNotificationCount = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_UNREAD_COUNT,
      item: value,
    });
  };
};

export const setEmployeesList = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_EMPLOYEES_LIST,
      item: value,
    });
  };
};

export const setRemoveEmployee = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_REMOVE_EMPLOYEE,
      item: value,
    });
  };
};

export const setJobsList = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_JOBS_LIST,
      item: value,
    });
  };
};

export const updateJobList = (value) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_JOBS_LIST,
      item: value,
    });
  };
};

export const setJobsSearch = (value: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_JOBS_SEARCH,
      item: value,
    });
  };
};

export const setJobsFetchState = (value: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_JOBS_FETCH_STATES,
      item: value,
    });
  };
};

export const setJobsSort = (value: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_JOBS_SORT,
      item: value,
    });
  };
};

export const setJobsDateRange = (value: object) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_JOBS_DATE_RANGE,
      item: value,
    });
  };
};

export const setReferralCode = (value: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_REFERRAL_CODE,
      item: value,
    });
  };
};

export const addOnlineUser = (value: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ADD_ONLINE_USER,
      item: value,
    });
  };
};

export const removeOnlineUser = (value: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.REMOVE_ONLINE_USER,
      item: value,
    });
  };
};

export const updateAllOnlineUser = (value: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_ALL_ONLINE_USER,
      item: value,
    });
  };
};

export const updateCurrentUserLastSeen = (value: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CURRENT_USER_LAST_SEEN,
      item: value,
    });
  };
};

export const closeOnlineOfflineSocket = (value: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CLOSE_ONLINE_OFFLINE_SOCKET,
      item: value,
    });
  };
};

export const updateCurrentUserLatestMessage = (value: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CURRENT_USER_LATEST_MESSAGE,
      item: value,
    });
  };
};

export const getOnlineUserList = (value: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.GET_ONLINE_USER_LIST,
      item: value,
    });
  };
};


export const triggerChatTab = (value: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.TRIGGER_CHAT_TAB,
      item: value,
    });
  };
};

export const reconnectInsideSocket = (value: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RECONNECT_INSIDE_SOCKET,
      item: value,
    });
  };
};